import React, { useContext } from "react";
import styled from "styled-components";
import TransactionContext from "../../../../Contexts/Transaction";
import Fixed from "../../../../assets/images/home/fixed.png";
import Float from "../../../../assets/images/home/float.svg";
import Loading from "../../../../assets/images/loading.svg";
import Guide from "../../../../assets/images/modal/guide.svg";
import TagsContext from "../../../../Contexts/TagsContext/TagsContext";

const CefiMobileList = () => {
  const checkcefilist = useContext(TransactionContext);
  const checkTags = useContext(TagsContext);
  return (
    <StyledWrapper>
      <StyledHeader>
        <div>
          <img src={Fixed} alt="flashift" />
          <span>Fixed Rate</span>
        </div>
        <div>
          <img src={Float} alt="flashift" />
          <span>Floating Rate</span>
        </div>
      </StyledHeader>
      <StyledItems>
        <StyledInfo>
          {checkcefilist.loading ? (
            <StyledLoading>
              <img src={Loading} alt="flashift" />
            </StyledLoading>
          ) : checkcefilist.exchangelist.length < 1 ||
            checkcefilist.mainminamount === "-1" ||
            checkcefilist.mainmaxamount === "-1" ? (
            <div className="active">
              <span className="list_amount">-</span>
              <span className="list_partnername">-</span>
              <span className="list_exchangetype">-</span>
              <span className="list_fixedtype">-</span>
              <span className="list_max">-</span>
              <span className="list_min">-</span>
              <p>
                <span>oops!</span>
                {/* checkcefilist.exchangenamefrom.toUpperCase() === checkcefilist.exchangenameto.toUpperCase() ? "The pair is not support" :
                                            checkcefilist.exchangelist.length < 1 ? "The amount cannot be below " + checkcefilist.mainminamount : null */}
                {checkcefilist.errormessage === "pair is not valid"
                  ? "The pair is not supported"
                  : checkcefilist.errormessage === "minimum problem"
                  ? "The amount cannot be below " + checkcefilist.mainminamount
                  : null}
              </p>
            </div>
          ) : (
            checkcefilist.exchangelist.map((exchangelistitem, index) => {
              return (
                <StyledItem
                  onClick={(event) => {
                    checkcefilist.HandleListCefi(event);
                    checkcefilist.SetSelect(index);
                  }}
                  key={index}
                  className={
                    checkcefilist.select === index ? "list active" : "list"
                  }
                >
                  <div style={{ justifyContent: "space-between" }}>
                    <span>
                      <span>
                        {exchangelistitem.exchange_type === "floating" ? (
                          <img src={Float} alt="flashift" />
                        ) : (
                          <img src={Fixed} alt="flashift" />
                        )}

                        <span className="list_partnername">
                          {exchangelistitem.partner_name}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div style={{ padding: "1px 10px 5px 10px" }}>
                    <span
                      style={{ fontSize: "1.2rem" }}
                      className="list_amount"
                    >
                      {exchangelistitem.amount}
                    </span>
                  </div>
                  {exchangelistitem?.tags?.length > 0 && (
                    <div className="tags">
                      {exchangelistitem?.tags.map((item) => {
                        return (
                          <>
                            <span className="tag">{item}</span>
                            {item === "Recommended" || item === "Best Rate" ? (
                              <span className="saving">
                                Save{" "}
                                <span>
                                  {checkcefilist.loading ||
                                  checkcefilist.exchangelist.length < 1 ||
                                  checkcefilist.mainminamount === "-1" ||
                                  checkcefilist.mainmaxamount ===
                                    "-1" ? null : (
                                    <>
                                      {(
                                        ((checkcefilist.exchangelist[index].amount -
                                          checkcefilist.exchangelist[
                                            checkcefilist.exchangelist.length -
                                              1
                                          ].amount) /
                                          checkcefilist.exchangelist[index]
                                            .amount) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </>
                                  )}
                                </span>{" "}
                              </span>
                            ) : null}
                            <StyledTooltip>
                              <img
                                src={Guide}
                                alt="flashift"
                                onClick={() => checkTags.tagsModalShow(index)}
                              />
                            </StyledTooltip>
                          </>
                        );
                      })}
                    </div>
                  )}
                  {/*<StyledSavePercent>
                                                <span>
                                                    {index === 0 ? (((checkcefilist.exchangelist[0].amount - checkcefilist.exchangelist[checkcefilist.exchangelist.length - 1].amount) / checkcefilist.exchangelist[0].amount) * 100).toFixed(2).toString() + "%" : ""}
                                                </span>
                                        </StyledSavePercent>*/}
                  <span className="list_exchangetype">
                    {exchangelistitem.exchange_type}
                  </span>
                  <span className="list_fixedtype">
                    {exchangelistitem.exchange_type === "floating"
                      ? "false"
                      : "true"}
                  </span>
                  <span className="list_max">
                    {exchangelistitem.max_amount}
                  </span>
                  <span className="list_min">
                    {exchangelistitem.min_amount}
                  </span>
                </StyledItem>
              );
            })
          )}
        </StyledInfo>
      </StyledItems>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;
const StyledHeader = styled.h5`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px auto 10px auto;
  img {
    width: 25px;
    margin-right: 5px;
  }
  span {
    position: relative;
    top: -7px;
  }
  @media (max-width: 768px) {
    img {
      width: 20px;
    }
    span {
      font-size: 1rem;
      top: -4px;
    }
  }
`;

const StyledSavePercent = styled.div`
  color: ${(props) => props.theme.color.ColorEight};
  position: relative;
  left: 17px;
`;

const StyledTitr = styled.div`
color:${(props) => props.theme.color.ColorTwo};
padding:10px;
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:space-between;
position:relative;
>span{
    width:40%;
    text-align:center;
&:first-child{
    display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
}
&:nth-child(3){
    width:20%;
    text-align:right;
    span{
        color:${(props) => props.theme.color.ColorEight};
        position: relative;
  top: 43px;
  right: 39px;
    }
      }
}
img {
    width: 24px;
    margin-right: 4px;
}
@media(max-width:550px){
>span{
    &:nth-child(3){
        span{
            position: absolute;
            top: 53px;
            right: 10px;
        }
          }
    }   
}
}
`;
const StyledInfo = styled.div`
  height: 200px;
  transition: all 300ms;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #787b86 #c2c2c2;
  width: 100%;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #787b86;
    border-radius: 8px;
    border: 1px solid #ffffff;
  }
  @media (max-width: 768px) {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid transparent;
    }
  }
`;
const StyledItems = styled.ul`
  position: relative;
  border-radius: ${(props) => props.theme.radius.RadiusOne};
  background: ${(props) => props.theme.color.ColorSix};
  box-shadow: ${(props) => props.theme.shadow.ShadowOne};
  li.active {
    animation: shadow 6.5s infinite;
    background: ${(props) => props.theme.color.ColorOne};
    border: 1px solid rgb(243 156 18 / 45%);
  }
  div.active {
    color: ${(props) => props.theme.color.ColorTwelve};
    p {
      text-align: center;
      padding: 50px 20px 0 20px;
      font-size: 1.1rem;
      span {
        font-size: 1.8rem;
        font-weight: bold;
        display: inline-block;
        width: 100%;
      }
    }
    > span {
      display: none;
    }
  }
  @keyframes shadow {
    0% {
      box-shadow: 0px 0 15px 1px rgb(243 156 18 / 20%);
    }
    50% {
      box-shadow: 2px 2px 15px 1px rgb(243 156 18 / 50%);
    }
    100% {
      box-shadow: 0px 0 15px 1px rgb(243 156 18 / 20%);
    }
  }
`;
const StyledItem = styled.li`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  padding: 3px 10px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: ${(props) => props.theme.color.ColorOne};
  border-radius: ${(props) => props.theme.radius.RadiusOne};
  color: ${(props) => props.theme.color.ColorTwo};
  padding: 7px 25px 6px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  .list_partnername {
    position: relative;
    top: -7px;
  }
  &:hover {
    background: ${(props) => props.theme.color.ColorEleven};
  }
  img {
    width: 24px;
    margin-right: 4px;
  }
  > span {
    width: 40%;
    text-align: center;
    &:nth-child(1) {
      display: flex;
      display: -ms-flexbox;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
    }
    &:nth-child(3) {
      width: 20%;
    }
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: start;
    margin-bottom: 2px;
  }
  em {
    opacity: 0.7;
  }
  .tag {
    border-radius: 3px;
    margin: 1px 10px 1px 0;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.9rem;
  }
  .saving {
    position: absolute;
    right: 15px;
    top: 11px;
  }
  .list_exchangetype,
  .list_max,
  .list_min,
  .list_fixedtype {
    display: none;
  }
`;
const StyledLoading = styled.div`
  text-align: center;
  position: absolute;
  width: 100%;
  padding-top: 80px;
  img {
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const StyledTooltip = styled.div`
  z-index: 10;
  cursor: pointer;
  position: absolute;
  right: 2px;
  bottom: 0;
  img {
    width: 23px;
    height: 23px;
    opacity: 0.9;
  }
  &:hover {
    > div {
      display: block;
    }
  }
  > div {
    display: none;
    position: absolute;
    right: -5px;
    bottom: 32px;
    width: 265px;
    background: ${(props) => props.theme.color.ColorOne};
    border: 1px solid ${(props) => props.theme.color.ColorEight};
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    font-size: 0.8rem;
    padding: 7px;
    z-index: 1;
    p {
      &:first-child {
        color: #f39c12;
      }
    }
  }
`;

export default CefiMobileList;

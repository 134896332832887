import React, { useContext } from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import TransactionContext from '../../../../Contexts/Transaction';
import Close from '../../../../assets/images/modal/close.svg';
import MobileClose from '../../../../assets/images/modal/mobileclose.svg';
import MobileBack from '../../../../assets/images/modal/mobileback.svg';
import Modal from '../../../../Components/Modal';
import Confirm from './Confirm';
import Finalizing from './Finalizing';
import StepOne from '../../../../assets/images/modal/step1.svg';
import Guide from '../../../../assets/images/modal/guide.svg';

const RecipientAddress = () => {
  const checkRecipient = useContext(TransactionContext);

  return (
    <Modal>
      {checkRecipient.modalone ? (
        <>
          <StyledHeader>
            <button onClick={checkRecipient.HandleClose}>
              <img src={Close} alt="flashift" />
              <img src={MobileClose} alt="flashift" />
            </button>
            <div>
              <span></span>
              <p>
                <span></span>
                <br />2
              </p>
              <span></span>
              <span></span>
            </div>
          </StyledHeader>
          <StyledContent>
            <StyledLeft>
              <h2>
                Enter your {checkRecipient.exchangenameto.toUpperCase()} address
              </h2>
              <StyledItem>
                <ul>
                  <li>
                    <span>Sending: </span>
                    <br />
                    <span className="captal" style={{ marginLeft: '0.3rem' }}>
                      {checkRecipient.cefivaluefrom}
                      {checkRecipient.exchangenamefrom === ''
                        ? ' BTC'
                        : ' ' +
                          ReactHtmlParser(checkRecipient.exchangenamefrom)}
                    </span>
                    <span
                      className={
                        checkRecipient.networkfrom ? 'networkfrom_item' : null
                      }
                    >
                      {checkRecipient.networkfrom}
                    </span>
                  </li>
                  <li>
                    <span>Getting: </span>
                    <br />
                    <span className="captal" style={{ marginLeft: '0.3rem' }}>
                      {checkRecipient.cefivalueto}{' '}
                      {checkRecipient.exchangenameto === ''
                        ? ' ETH'
                        : ReactHtmlParser(checkRecipient.exchangenameto)}
                    </span>
                    <span
                      className={
                        checkRecipient.networkto ? 'networkto_item' : null
                      }
                    >
                      {checkRecipient.networkto}
                    </span>
                  </li>
                  <li>
                    <span>Service: </span>
                    <br />
                    <span>{checkRecipient.service}</span>
                  </li>
                  <li>
                    <span>Exchange Type: </span>
                    <br />
                    <span>
                      {checkRecipient.exchangetype.charAt(0).toUpperCase() +
                        checkRecipient.exchangetype.slice(1)}
                    </span>
                  </li>
                </ul>
              </StyledItem>
              <StyledMobileTitle>
                {checkRecipient.coinslist.filter(
                  (item) => item.name === checkRecipient.exchangenameto
                )?.[0]?.memo
                  ? 'Enter your destination address & MEMO'
                  : 'Enter your destination address'}
              </StyledMobileTitle>
              {checkRecipient.message ? (
                <StyledMessage>
                  {' '}
                  Please enter your{' '}
                  <em className="captal">
                    "{checkRecipient.exchangenameto}"
                  </em>{' '}
                  wallet address
                </StyledMessage>
              ) : null}
              <StyledItem className="address_value">
                <h5>
                  <span>
                    Enter your{' '}
                    <em className="captal">{checkRecipient.exchangenameto}</em>{' '}
                    address
                  </span>
                  <StyledTooltip>
                    <img src={Guide} alt="flashift" />
                    <div>
                      <p>Address</p>
                      <p>
                        You will receive your exchanged assets in this address.
                      </p>
                    </div>
                  </StyledTooltip>
                </h5>
                <textarea
                  onChange={checkRecipient.HandleAddress}
                  value={checkRecipient.address}
                  type="text"
                  placeholder="Enter your recipient here"
                  autoFocus
                  required
                />
              </StyledItem>
              {checkRecipient.coinslist.filter(
                (item) => item.name === checkRecipient.exchangenameto
              )?.[0]?.memo && (
                <StyledItem className="address_value">
                  <h5>
                    <span>Enter your extra ID (if needed)</span>
                    <StyledTooltip>
                      <img src={Guide} alt="flashift" />
                      <div>
                        <p>MEMO</p>
                        <p>
                          Please don't forget to specify the extra ID for your
                          payout address if needed.
                        </p>
                      </div>
                    </StyledTooltip>
                  </h5>
                  <textarea
                    onChange={checkRecipient.HandleMemo}
                    value={checkRecipient.memo}
                    type="text"
                    placeholder="Enter your extra ID here (if needed)"
                  />
                </StyledItem>
              )}

              <StyledButton>
                <button onClick={checkRecipient.HandleClose}>
                  Back
                  <img src={MobileBack} alt="flashift" />
                </button>
                <button type="submit" onClick={checkRecipient.HandleModaltwo}>
                  Next
                </button>
              </StyledButton>
            </StyledLeft>
            <StyledRight>
              <StyledTooltipPoint id="pointone">
                <StyledTooltipStep id="popoverone">
                  <div>
                    <p>
                      <span>Step 1 </span>Select rate
                    </p>
                    <p>
                      Select the exchange service which meets your requirements
                    </p>
                  </div>
                </StyledTooltipStep>
              </StyledTooltipPoint>
              <StyledTooltipPoint id="pointtwo">
                <StyledTooltipStep id="popovertwo">
                  <div>
                    <p>
                      <span> Step 2</span> Enter address
                    </p>
                    <p>
                      {' '}
                      {checkRecipient.coinslist.filter(
                        (item) => item.name === checkRecipient.exchangenameto
                      )?.[0]?.memo
                        ? 'Enter your destination address & MEMO'
                        : 'Enter your destination address'}
                    </p>
                  </div>
                </StyledTooltipStep>
              </StyledTooltipPoint>

              <StyledTooltipPoint id="pointthree">
                <StyledTooltipStep id="popoverthree">
                  <div>
                    <p>
                      <span> Step 3</span> Conform
                    </p>
                    <p>Reveiw everything before conforming</p>
                  </div>
                </StyledTooltipStep>
              </StyledTooltipPoint>
              <StyledTooltipPoint id="pointfour">
                <StyledTooltipStep id="popoverfour">
                  <div>
                    <p>
                      <span>Step 4</span> Receive
                    </p>
                    <p>You will get your coins in a few minutes</p>
                  </div>
                </StyledTooltipStep>
              </StyledTooltipPoint>
              <img src={StepOne} alt="flashift" />
            </StyledRight>
          </StyledContent>
        </>
      ) : null}
      {checkRecipient.modaltwo ? (
        <Confirm onClick={checkRecipient.HandleModalthree} />
      ) : null}
      {checkRecipient.modalthree ? <Finalizing /> : null}
      {checkRecipient.modalfour ? <Confirm /> : null}
    </Modal>
  );
};

const StyledHeader = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  div {
    span {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.color.ColorFive};
      margin: 0 3px;
      display: inline-block;
    }
    p {
      color: #f39c12;
      display: inline-block;
      text-align: center;
      position: relative;
      top: 21px;
      span {
        background: #f39c12;
        border: 1px solid ${(props) => props.theme.color.ColorEight};
      }
    }
  }
  button {
    img {
      &:last-child {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    > div {
      display: none;
    }
    button {
      position: absolute;
      right: 8px;
      top: 8px;
      img {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }
`;
const StyledContent = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
`;
const StyledRight = styled.div`
  width: 290px;
  margin: 20px;
  padding-top: 60px;
  position: relative;
  #pointone {
    &:hover {
      #popoverone {
        display: block;
      }
    }
  }
  #pointtwo {
    &:hover {
      #popovertwo {
        display: block;
      }
    }
  }
  #pointthree {
    &:hover {
      #popoverthree {
        display: block;
      }
    }
  }
  #pointfour {
    &:hover {
      #popoverfour {
        display: block;
      }
    }
  }
  img {
    width: 100%;
  }
  @media (max-width: 992px) {
    img {
      display: none;
    }
  }
`;
const StyledTooltipPoint = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  &:nth-child(1) {
    right: 117px;
    top: 57px;
    > div {
      right: -36px;
      top: -74px;
    }
  }
  &:nth-child(2) {
    right: 5px;
    top: 166px;
    > div {
      right: 0;
      top: 65px;
    }
  }
  &:nth-child(3) {
    right: 115px;
    top: 278px;
    > div {
      right: -86px;
      bottom: -52px;
    }
  }
  &:nth-child(4) {
    right: 228px;
    top: 167px;
    > div {
      right: -169px;
      top: 53px;
    }
  }
`;
const StyledTooltipStep = styled.div`
  display: none;
  position: absolute;
  width: 260px;
  background: ${(props) => props.theme.color.ColorOne};
  border: 1px solid ${(props) => props.theme.color.ColorEight};
  border-radius: ${(props) => props.theme.radius.RadiusTwo};
  font-size: 0.8rem;
  padding: 7px;
  z-index: 1;
  p {
    &:first-child {
      span {
        color: #f39c12;
      }
    }
  }
`;
const StyledLeft = styled.div`
  width: calc(100% - 370px);
  margin: 20px;
  h2 {
    margin-bottom: 25px;
  }
  > div {
    &:nth-child(2) {
      border-radius: ${(props) => props.theme.radius.RadiusThree};
    }
    &:nth-child(4),
    &:nth-child(5) {
      border-radius: ${(props) => props.theme.radius.RadiusTwo};
    }
  }
  @media (max-width: 992px) {
    width: 90%;
    margin: 0 auto;
    .address_value {
      display: flex;
      flex-flow: row-reverse;
      justify-content: space-between;
      background: rgba(196, 201, 209, 0.32);
      border-radius: 8px !important;
      padding: 5px 10px;
      margin: 17px auto;
      input,
      textarea {
        color: #fff;
        font-size: 1rem;
      }
    }
    h2 {
      margin-bottom: 20px;
      text-align: center;
    }
  }
`;
const StyledMobileTitle = styled.h4`
  margin-top: 22px;
`;
const StyledItem = styled.div`
  background: ${(props) => props.theme.color.ColorTen};
  width: 550px;
  max-width: 100%;
  padding: 15px;
  margin: 15px 0;
  .networkfrom_item {
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  .networkto_item {
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  ul {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    li {
      text-align: center;
      .captal {
        color: #f39c12;
      }
      > span {
        margin: 5px auto;
        display: inline-block;
        &:first-child {
          color: ${(props) => props.theme.color.ColorFive};
        }
        &:last-child {
          color: #f39c12;
        }
      }
    }
  }

  h5 {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    top: 4px;
    margin-left: 5px;
    position: relative;
    img {
      width: 20px;
    }
  }
  input,
  textarea {
    color: #f39c12;
    width: 100%;
  }
  @media (max-width: 992px) {
    width: 100%;
    background: none;
    margin: 0;
    padding: 0;
    h5 {
      span {
        display: none;
      }
    }
    li {
      width: 100%;
      text-align: left !important;
      margin: 3px auto;
      br {
        display: none;
      }
      span {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
`;
const StyledTooltip = styled.div`
  cursor: pointer;
  &:hover {
    > div {
      display: block;
    }
  }
  > div {
    display: none;
    position: absolute;
    width: 260px;
    right: 0;
    background: ${(props) => props.theme.color.ColorOne};
    border: 1px solid ${(props) => props.theme.color.ColorEight};
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    font-size: 0.8rem;
    padding: 7px;
    z-index: 1;
    p {
      &:first-child {
        color: #f39c12;
      }
    }
  }
`;

const StyledButton = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: left;
  width: 500px;
  max-width: 100%;
  button {
    margin: 10px;
    padding: 10px 40px;
    font-size: 1.1rem;
    border-radius: ${(props) => props.theme.radius.RadiusThree};
    img {
      display: none;
    }
    &:first-child {
      background: ${(props) => props.theme.color.ColorFive};
      color: ${(props) => props.theme.color.ColorOne};
    }
    &:last-child {
      background: #f39c12;
      color: ${(props) => props.theme.color.ColorTwo};
    }
  }
  @media (max-width: 992px) {
    width: 100%;
    button {
      img {
        display: block;
      }

      &:first-child {
        background: unset;
        color: unset;
        position: absolute;
        top: 10px;
        left: 7px;
        margin: 0;
        padding: 0;
        font-weight: normal;
        background: none;
        font-size: 0;
      }
      &:last-child {
        width: 100%;
        border-radius: 8px;
        margin: 0px auto;
        padding: 8px 40px;
      }
    }
  }
`;

const StyledMessage = styled.h6`
  color: ${(props) => props.theme.color.ColorTwelve};
`;
export default RecipientAddress;

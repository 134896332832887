import React from "react";
import styled from "styled-components";

const WalletButton = () => {
  return (
    <StyledBlur>
      <StyledButton>
        <button className="button">Connect Wallet</button>
      </StyledButton>
    </StyledBlur>
  );
};

const StyledBlur = styled.div`
  filter: blur(4px);
`;

const StyledButton = styled.div`
  button {
    background: ${(props) => props.theme.color.ColorEight};
    color: #ffffff;
    border-radius: 8px;
    font-size: 1.1rem;
    padding: 3px 15px;
    margin: 12px 20px 0 0;
    position: relative;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;
export default WalletButton;

import React, { useState, useContext } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import TransactionContext from "../../../Contexts/Transaction";
import ThemeContext from "../../../Contexts/ThemeContext";
import ReactHtmlParser from "react-html-parser";
import Card from "../../../Components/Card";
import Change from "../../../assets/images/home/changeicon.svg";
import LightChange from "../../../assets/images/home/lightchangeicon.svg";
import PriceChange from "../../../assets/images/home/pricechange.svg";
import LightPriceChange from "../../../assets/images/home/lightpricechange.svg";
import Loading from "../../../assets/images/loading.svg";
import CardTitle from "../../../Components/CardTitle";
import CefiMobileList from "./CefiMobileList";
import CefiList from "./CefiList";
import Fixed from "../../../assets/images/home/buttonfixed.png";
import Float from "../../../assets/images/home/buttonfloat.svg";

const CefiPanel = () => {
  const checkcefi = useContext(TransactionContext);
  const checkthemecefi = useContext(ThemeContext);
  const [tokenprice, SetTokenPrice] = useState(true);
  const HandleTokenPrice = () => {
    SetTokenPrice(!tokenprice);
  };
  return (
    <>
      <Card>
        <CardTitle>Exchange</CardTitle>
        <StyledWrapper>
          {/*from*/}
          <StyledTop>
            <StyledItem className="crypto_one">
              <p>
                <span>From</span>
              </p>
              <StyledCrypto>
                <StyledLeft>
                  <StyledTitr
                    className="crypto_item"
                    onClick={checkcefi.Handlecefishowfrom}
                  >
                    <label>
                      {checkcefi.exchangefullnamefrom === ""
                        ? "Bitcoin"
                        : ReactHtmlParser(checkcefi.exchangefullnamefrom)}
                    </label>
                    <h6>
                      <p>
                        {checkcefi.exchangeiconfrom === "" ? (
                          <em className="icon-btc"></em>
                        ) : (
                          ReactHtmlParser(checkcefi.exchangeiconfrom)
                        )}
                      </p>
                      <p className="captal currency_from">
                        <span>{checkcefi.exchangenamefrom}</span>
                        <span
                          className={
                            checkcefi.networkfrom ? "networkfrom_item" : null
                          }
                        >
                          {checkcefi.networkfrom}
                        </span>
                        <small className="captal currency_symbol">
                          {checkcefi.exchangesymbolfrom}
                        </small>
                      </p>
                    </h6>
                  </StyledTitr>
                </StyledLeft>

                <StyledRight className="crypto_one">
                  <label htmlFor="ceficryptofrom"></label>
                  <div>
                    <input
                      id="ceficryptofrom"
                      type="text"
                      placeholder="0.1"
                      value={checkcefi.cefivaluefrom}
                      onChange={checkcefi.Handlecefivaluefrom}
                      autoComplete="off"
                      inputMode="decimal"
                      autoFocus
                    />
                  </div>
                </StyledRight>
              </StyledCrypto>
            </StyledItem>
            <StyledChange>
              <button onClick={checkcefi.HandleCefiChange}>
                {checkthemecefi.isDark ? (
                  <img src={Change} alt="flashift" />
                ) : (
                  <img src={LightChange} alt="flashift" />
                )}
              </button>
            </StyledChange>
            {/*to*/}
            <StyledItem className="crypto_two">
              <p>
                <span>To</span>
              </p>
              <StyledCrypto>
                <StyledLeft>
                  <StyledTitr
                    className="crypto_item"
                    onClick={checkcefi.Handlecefishowto}
                  >
                    <label>
                      {checkcefi.exchangefullnameto === ""
                        ? "Ethereum"
                        : ReactHtmlParser(checkcefi.exchangefullnameto)}
                    </label>
                    <h6>
                      <p>
                        {checkcefi.exchangeiconto === "" ? (
                          <em className="icon-eth"></em>
                        ) : (
                          ReactHtmlParser(checkcefi.exchangeiconto)
                        )}
                      </p>
                      <p className="captal currency_to">
                        <span>{checkcefi.exchangenameto}</span>
                        <span
                          className={
                            checkcefi.networkto ? "networkto_item" : null
                          }
                        >
                          {checkcefi.networkto}
                        </span>
                        <small className="captal currency_symbol">
                          {checkcefi.exchangesymbolto}
                        </small>
                      </p>
                    </h6>
                  </StyledTitr>
                </StyledLeft>
                <StyledRight className="crypto_two">
                  <label htmlFor="ceficryptoto"></label>
                  <div>
                    {checkcefi.loading ? (
                      <StyledLoading>
                        <img src={Loading} alt="flashift" />
                      </StyledLoading>
                    ) : (
                      <input
                        id="ceficryptoto"
                        type="text"
                        value={checkcefi.cefivalueto}
                        readOnly
                      />
                    )}
                  </div>
                </StyledRight>
              </StyledCrypto>
            </StyledItem>
            <MediaQuery maxDeviceWidth={768}>
              <CefiMobileList />
            </MediaQuery>
            <StyledButton>
              {checkcefi.loading ||
              checkcefi.exchangelist.length < 1 ||
              /*(checkcefi.exchangenamefrom === checkcefi.exchangenameto)
                  ||*/
              checkcefi.mainminamount === "-1" ||
              checkcefi.mainmaxamount === "-1" ? (
                <button className="disabled" disabled>
                  waiting ...
                </button>
              ) : (
                <button className="button" onClick={checkcefi.HandleModalmain}>
                  <span>
                    {" "}
                    Exchange by{" "}
                    {checkcefi.exchangetype === "floating" ? (
                      <img src={Float} alt="flashift" />
                    ) : (
                      <img src={Fixed} alt="flashift" />
                    )}
                    {checkcefi.service}
                  </span>
                </button>
              )}
            </StyledButton>
          </StyledTop>
          <StyledInfo>
            <ul>
              {/* <li>
                <span>Price Tolerance</span>
                <span>1 %</span>
              </li>
              <li>
                <span>Gas Price</span>
                <span>Custom(92.13 GWEI)</span>
              </li>
              */}
              <li>
                <span>Exchange rate</span>
                <button onClick={HandleTokenPrice}>
                  {checkthemecefi.isDark ? (
                    <img src={PriceChange} alt="flashift" />
                  ) : (
                    <img src={LightPriceChange} alt="flashift" />
                  )}
                </button>
                {checkcefi.loading ||
                checkcefi.exchangelist.length < 1 ||
                /*(checkcefi.exchangenamefrom === checkcefi.exchangenameto)
                    ||*/
                checkcefi.mainminamount === "-1" ||
                checkcefi.mainmaxamount === "-1" ? (
                  <span></span>
                ) : tokenprice ? (
                  <span className="captal">
                    1 {checkcefi.exchangenamefrom} ={" "}
                    {parseFloat(
                      checkcefi.cefivalueto / checkcefi.cefivaluefrom
                    ).toFixed(8) === "0.00000000"
                      ? parseFloat(
                          checkcefi.cefivalueto / checkcefi.cefivaluefrom
                        ).toFixed(16)
                      : parseFloat(
                          checkcefi.cefivalueto / checkcefi.cefivaluefrom
                        ).toFixed(8)}{" "}
                    {checkcefi.exchangenameto}
                  </span>
                ) : (
                  <span className="captal">
                    1 {checkcefi.exchangenameto} ={" "}
                    {parseFloat(
                      checkcefi.cefivaluefrom / checkcefi.cefivalueto
                    ).toFixed(8) === "0.00000000"
                      ? parseFloat(
                          checkcefi.cefivaluefrom / checkcefi.cefivalueto
                        ).toFixed(16)
                      : parseFloat(
                          checkcefi.cefivaluefrom / checkcefi.cefivalueto
                        ).toFixed(8)}{" "}
                    {checkcefi.exchangenamefrom}
                  </span>
                )}
              </li>
            </ul>
          </StyledInfo>
        </StyledWrapper>
      </Card>
    </>
  );
};

const StyledWrapper = styled.div`
  padding: 15px 15px 0 15px;
  color: ${(props) => props.theme.color.ColorTwo};
`;
const StyledTop = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;
const StyledItem = styled.div`
  > p {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    > span {
      &:first-child {
        font-weight: 600;
        font-size: 1.1rem;
      }
    }
  }
  @media (max-width: 768px) {
    background: ${(props) => props.theme.color.ColorSix};
    padding: 10px 10px 20px 10px;
    border-radius: 8px;
  }
`;
const StyledCrypto = styled.div`
background:${(props) => props.theme.color.ColorSix};
border-radius:${(props) => props.theme.radius.RadiusOne};
box-shadow:${(props) => props.theme.shadow.ShadowOne};
position:relative;
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:space-between;
padding:10px;
margin-top:10px;
@media(max-width:768px){
  background: transparent;
  box-shadow: none;
  padding: 10px 0;
  margin-top: 13px;
    label{
        position: relative;
top: 28px;
margin-bottom: 0 !important;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
max-width: 300px;
height: 16px;
    }
}
label{
    color:${(props) => props.theme.color.ColorTwo};
    opacity: 0.5;
    display: inline-block;
    margin-bottom: 8px;
    }
}
`;

const StyledLeft = styled.div`
  width: 47%;
  h6 {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    i {
      color: ${(props) => props.theme.color.ColorEight};
      font-size: 1.4rem;
      margin-right: 3px;
    }
    @media (max-width: 768px) {
      background: ${(props) => props.theme.color.ColorOne};
      border-radius: 8px;
      padding: 9px 1px 8px 9px;
      width: 100%;
      margin-top: -40px;
    }
  }
`;
const StyledTitr = styled.div`
  cursor: pointer;
  .networkfrom_item {
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  .networkto_item {
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  .currency_symbol {
    display: none;
  }
  p {
    display: inline-block;
    position: relative;
    top: 2px;
    font-size: 1.1rem;
    height: 27px;
    &:nth-child(2) {
      margin-left: 5px;

      &:before {
        position: absolute;
        content: "";
        background: url("./assets/images/arrowdowncolor.svg") no-repeat left
          center;
        width: 10px;
        height: 10px;
        right: -16px;
        top: 7px;
      }
    }
    img {
      width: 21px;
      margin-right: 3px;
    }
    em {
      font-size: 1.7rem;
      color: #f39c12;
      position: relative;
    }
    @media (max-width: 768px) {
      top: 4px;
      font-size: 1rem;
      em {
        font-size: 1.5rem;
        top: -2px;
      }
    }
  }
`;

const StyledRight = styled.div`
  width: 52%;
  text-align: right;
  > div {
    position: relative;
  }

  input {
    color: ${(props) => props.theme.color.ColorTwo};
    text-align: right;
    font-weight: bold;
    font-size: 1.1rem;
    width: 100%;
  }
  @media (max-width: 768px) {
    input {
      background: ${(props) => props.theme.color.ColorOne};
      border-radius: 8px;
      width: 100%;
      padding: 9px 5px;
    }
    > div {
      margin-top: -39px;
      background: ${(props) => props.theme.color.ColorOne};
      border-radius: 8px;
      width: 100%;
      height: 43px;
    }
  }
`;
const StyledChange = styled.div`
  text-align: center;
  margin-top: 9px;
  button {
    img {
      width: 30px;
    }
  }
`;
const StyledButton = styled.div`
  margin-top: 5%;
  button {
    background: ${(props) => props.theme.color.ColorEight};
    color: #ffffff;
    border-radius: 8px;
    font-size: 1.1rem;
    width: 100%;
    height: 47px;
    img {
      max-width: 25px;
      position: relative;
      top: 8px;
    }
    span {
      margin-top: -10px;
      position: relative;
      top: -3px;
      font-size: 1.1rem;
    }
  }
  .disabled {
    box-shadow: none !important;
    cursor: auto;
    opacity: 0.5;
  }
`;
const StyledInfo = styled.div`
  padding: 2% 0;
  li {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px 0;
    button {
      margin-top: 4px;
      img {
        width: 18px;
        transform: rotate(90deg);
      }
    }
    span {
      width: 45%;
      &:last-child {
        text-align: right;
        position: relative;
      }
    }
  }
`;
const StyledLoading = styled.div`
  text-align: center;
  position: absolute;
  right: 10px;
  top: -5px;
  img {
    animation: rotation 1s linear infinite;
    width: 30px;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  @media (max-width: 768px) {
    top: 6px;
  }
`;
export default CefiPanel;

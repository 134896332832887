import React,{useContext} from "react"
import styled from "styled-components";
import MediaQuery from 'react-responsive'
import { NavLink } from 'react-router-dom'
import TransactionContext from "../../Contexts/Transaction";

const Menu = (props) => {
  const checkMenu =  useContext(TransactionContext);
  return (
    <>
    <StyledItems>
      <MediaQuery maxDeviceWidth={768}>
          <StyledItem>
            <button onClick={checkMenu.HandleMobileHistory}>
            History
            </button>
          </StyledItem>
          <StyledItem>
            <button onClick={checkMenu.HandleMobileTracker}>
            SwapTracker
            </button>
          </StyledItem>
      </MediaQuery>
      <StyledItem>
        <StyledLink href="https://flashift.app/blog/how-to-swap-across-different-blockchains/" target="_top">
        How it works
        </StyledLink>
      </StyledItem>
      <StyledItem>
        <StyledLink href="https://flashift.app/faq" target="_top">
        FAQs
        </StyledLink>
      </StyledItem>
      <StyledItem>
        <StyledLink href="https://flashift.app/blog/" target="_top">
        Blog
        </StyledLink>
      </StyledItem>
      <StyledItem>
        <StyledLink href="https://flashift.app/#footercontact" target="_top">
        Contact us
        </StyledLink>
      </StyledItem>
    </StyledItems>

    </>
  )
}
const StyledItems = styled.ul`
@media(min-width:993px){
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:space-between;
padding-top: 15px;
gap: 2.5rem;
}
@media(max-width:992px){
  width:100%;
}

`
const StyledItem = styled.li`
text-align: center;
font-size: 1.1rem;
button{
  font-size: 1.1rem;
  color:${(props) => props.theme.color.ColorTwo};
&:hover{
color:#F39C12;
}
}
@media(max-width:992px){
text-align: left;
padding: 10px 0;
}
`
const StyledLink = styled.a`
color:${(props) => props.theme.color.ColorTwo};
&:hover{
color:#F39C12;
}
`
export default Menu;
import React, {useContext } from "react";
import { NavLink } from 'react-router-dom'
import styled from "styled-components";
import TransactionContext from "../../Contexts/Transaction";
import ThemeContext from "../../Contexts/ThemeContext";
import Menu from "../../Components/Menu"
import Logo from "../../Components/Logo"
import ThemeButton from "../../Components/ThemeButton"
import WalletButton from "../../Components/WalletButton"
import Wallet from "../../assets/images/home/wallet.svg"
import LightWallet from "../../assets/images/home/lightwallet.svg"
import Earth from "../../assets/images/home/earth.png"
import MediaQuery from 'react-responsive'

const TopBar = () => {
    const checkTopBar = useContext(TransactionContext);
    const checkoutDrawer = useContext(ThemeContext)

    return (
        <StyledWrapper>
            <Logo />
            <StyledRight menu={checkTopBar.menu}>
                {/*<WalletButton />*/}
                <Menu />
            </StyledRight>
            <StyledMobileIcon onClick={checkTopBar.HandleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </StyledMobileIcon>
            {/* <StyledMobileWallet onClick={checkTopBar.HandleModalWallet}> */}
            {/* <StyledMobileWallet>
                {checkoutDrawer.isDark ?
                    <img src={Wallet} alt="flashift" />
                    :
                    <img src={LightWallet} alt="flashift" />
                }
            </StyledMobileWallet> */}
        </StyledWrapper>
    )
}
const StyledWrapper = styled.header`
background:${(props) => props.theme.color.ColorOne};
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:space-between;
padding:10px 20px 5px 20px;
position:relative;
z-index:10;
@media(max-width:768px){
padding:3px 20px 0 20px;
}
`
const StyledRight = styled.div`
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:space-between;
@media(max-width:992px){
    background:${(props) => props.theme.color.ColorNine};
    position: absolute;
    width: 100%;
    left: 0;
    top: 70px;
    padding: 20px;
    display:${({ menu }) => menu ? 'block' : 'none'};
}
@media(max-width:768px){
    top: 45px;
}
`
const StyledMobileIcon = styled.div`
width: 25px;
position: absolute;
right: 17px;
top: 25px;
cursor: pointer;
display:none;
div{
    background:${(props) => props.theme.color.ColorTwo};
    width: 100%;
height: 3px;
margin: 5px auto;
}
@media(max-width:992px){
display:block;
top:23px;
}
@media(max-width:768px){
    top: 10px;
    }
`
const StyledMobileWallet = styled.button`
display:none;
position: absolute;
top: 12px;
left: 17px;
img{
    width: 23px;
}
@media(max-width:768px){
 display:block;
}
`
const StyledLink = styled(NavLink)`
color:${(props) => props.theme.color.ColorTwo};
padding-top: 12px;
margin-left: 10px;
img{
    width: 20px;
    position: relative;
    top: 6px;
}
@media(max-width:992px){
    position: absolute;
    right: 97px;
    top: 11px;
}
@media(max-width:768px){
    position: absolute;
    left: 40px;
    top: -4px;
}
`
export default TopBar;
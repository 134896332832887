/*-----background-----*/ export const ColorOne = "#ffffff";
/*-----main text-----*/ export const ColorTwo = "#111a2b";
/*-----card bg-----*/ export const ColorThree = "rgb(241, 242, 246)";
/*-----card title-----*/ export const ColorFour = "rgb(199 201 207)";
/*-----slogan-----*/ export const ColorFive = "#111a2b";
/*-----balance bg-----*/ export const ColorSix = "#686de0";
/*-----menu icon bg-----*/ export const ColorSeven = "#686de0";
/*-----orange-----*/ export const ColorEight = "#686de0";
/*-----Modal bg-----*/ export const ColorNine = " rgba(98, 110, 212,0.9)";
/*-----Modalinput bg-----*/ export const ColorTen = "rgba(255, 255, 255, 0.8)";
/*-----Mobile main bg-----*/ export const ColorEleven = "#2A364A";
/*-----error-----*/ export const ColorTwelve = "rgb(201, 43, 66)";
/*-----gradient-----*/ export const ColorThirteen =
  "linear-gradient(180deg, #ef5350 0%, #ffaa00 100%)";

export const RadiusOne = "8px";
export const RadiusTwo = "8px";
export const RadiusThree = "8px";

export const ShadowOne =
  "rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px";

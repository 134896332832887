import React , { useContext }from "react"
import styled from "styled-components";
import { NavLink } from 'react-router-dom'
import ThemeContext from "../../Contexts/ThemeContext";
import LogoPic from "../../assets/images/logo.svg"
import LightLogoPic from "../../assets/images/lightlogo.svg"

const Logo = () => {
    const checkoutDrawer = useContext(ThemeContext)
    return (
        <StyledWrapper>
            <StyledLink to="/">
            { checkoutDrawer.isDark ? 
                <img src={LogoPic} alt="flashift" />
                :
                <img src={LightLogoPic} alt="flashift" />
                }
            </StyledLink>
            <StyledSlogan>
            save moments
            <br/>
             and money
            </StyledSlogan>
        </StyledWrapper>
    )
}
const StyledWrapper = styled.h1`
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
font-size:1.2rem;
img{
    width:173px;
    position: relative;
    top: -13px;
}
@media(max-width:768px){
margin: 0 auto;
img{
    width:120px;
}
}
`
const StyledLink = styled(NavLink)`
    height: 58px;
    @media(max-width:768px){
        height: 42px;
        padding-top: 6px;
    }
`
const StyledSlogan = styled.span`
color:${(props) => props.theme.color.ColorFive};
margin:1.5px 0 0 20px;
display:inline-block;
@media(max-width:768px){
    display:none;
}
`
export default Logo;
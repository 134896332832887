import React, { useState, useContext } from "react";
import styled from "styled-components";
import ThemeContext from "../../Contexts/ThemeContext";

const ThemeButton = () => {
  const checkoutDrawer = useContext(ThemeContext);
  const [checked, setChecked] = useState(true);
  const handleCheck = (event) => {
    setChecked(!checked);
  };
  return (
    <StyleButton onClick={checkoutDrawer.toggleTheme}>
      <StyleLabel checked={checked} dark={checkoutDrawer.isDark} for="check" />
      <StyledInput
        onChange={handleCheck}
        type="checkbox"
        id="check"
        name="check"
      />
    </StyleButton>
  );
};

const StyleButton = styled.button`
  background: ${(props) => props.theme.color.ColorFour};
  position: relative;
  width: 40px;
  height: 20px;
  cursor: pointer;
  border-radius: 8px;
  top: 18px;
  margin-left: 10px;
  @media (max-width: 992px) {
    margin-right: 30px;
  }
  @media (max-width: 768px) {
    margin: 5px auto;
    position: absolute;
    top: 9px;
    right: 52px;
  }
`;
const StyledInput = styled.input`
  width: 100%;
  height: 20px;
  opacity: 0;
  cursor: pointer;
  position: relative;
  z-index: 100;
`;
const StyleLabel = styled.label`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: ${({ checked }) => (checked ? "translateX(19px)" : "0")};
  background: ${({ dark }) =>
    dark
      ? "url(./assets/images/theme/moon.svg) no-repeat center center"
      : "url(./assets/images/theme/sun.svg) no-repeat center center"};
  background-size: cover;
  left: 3px;
  top: 2.5px;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
`;

export default ThemeButton;

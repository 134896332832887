import React, { useContext } from "react";
import styled from "styled-components";
import TagsContext from "../../Contexts/TagsContext/TagsContext";

const Modal = ({ children }) => {
  const checkTags = useContext(TagsContext);
  const handleStop = (event) => {
    event.stopPropagation();
  };
  return (
    <StyledWrapper
      className="modal_item"
      // onClick={checkTags.tagsModalHide}
    >
      <StyledInfo onClick={handleStop} className="modal_info">
        {children}
      </StyledInfo>
    </StyledWrapper>
  );
};
const StyledWrapper = styled.div`
  color: ${(props) => props.theme.color.ColorTwo};
  position: fixed;
  width: 100%;
  min-height: 100%;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 10;
  overflow: auto;
`;
const StyledInfo = styled.div`
  background: ${(props) => props.theme.color.ColorNine};
  border-radius: ${(props) => props.theme.radius.RadiusOne};
  position: absolute;
  top: 17%;
  left: 17%;
  width: 66%;
  padding: 20px 20px 0 20px;
  @media (max-width: 1300px) {
    left: 5%;
    width: 90%;
  }
  @media (max-width: 992px) {
    left: 4%;
    width: 92%;
    top: 13%;
    padding: 10px;
  }
  @media (max-width: 768px) {
    top: 9%;
  }
`;
export default Modal;

import React, { useState} from "react";
import ThemeContext  from "./ThemeContext";

  const ThemeState = ({children}) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUser = localStorage.getItem("isdark");
    return isDarkUser ? JSON.parse(isDarkUser) : true;
  });

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem("isdark", JSON.stringify(!prevState));
      return !prevState;
    });
  };
  
        return (
          <ThemeContext.Provider value={{ isDark, toggleTheme }}>
            { children }
            </ThemeContext.Provider>
        );
    }


export default ThemeState;

import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ThemeContext from "./Contexts/ThemeContext";
import Home from "./View/Home";
import dark from "./Theme/Dark";
import light from "./Theme/Light";

const App = () => {
  const checkTheme = useContext(ThemeContext);
  return (
    <ThemeProvider theme={checkTheme.isDark ? dark : light}>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/ExchangeInfo" element={<Home />}></Route>
      </Routes>
    </ThemeProvider>
  );
};
export default App;

import React, { useState } from "react";
import TagsContext from "./TagsContext";

const TagsState = ({ children }) => {
  const [tagsModal, setTagsModal] = useState(false);

  const [tagId, setTagId] = useState();
  const tagsModalShow = (index) => {
    setTagId(index);
    setTagsModal(() => {
      localStorage.setItem("modal", true);
      return true;
    });
    document.querySelector(".background_container").style.filter = "blur(4px)";
    document.querySelector(".child_wrapper").style.overflow = "hidden";
  };

  const tagsModalHide = () => {
    setTagsModal(() => {
      localStorage.setItem("modal", false);
      return false;
    });
    document.querySelector(".background_container").style.filter = "blur(0)";
    document.querySelector(".child_wrapper").style.overflow = "auto";
  };

  return (
    <TagsContext.Provider
      value={{ tagsModal, tagsModalShow, tagsModalHide, tagId }}
    >
      {children}
    </TagsContext.Provider>
  );
};

export default TagsState;

import React from "react";
import ThemeState from "./Contexts/ThemeContext/ThemeState";
import TransactionState from "./Contexts/Transaction/TransactionState";
import App from "./App";
import TagsState from "./Contexts/TagsContext/TagsState";

const AppWrapper = () => {
  return (
    <ThemeState>
    <TransactionState>
    <TagsState>
    <App />
    </TagsState>
     </TransactionState>
      </ThemeState>
  );
};

export default AppWrapper;


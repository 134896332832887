import React from "react";
import ReactDOM from "react-dom";
import AppWrapper from "./AppWrapper";
import { BrowserRouter } from "react-router-dom";
ReactDOM.render(
  <BrowserRouter>
    <AppWrapper/>
  </BrowserRouter>,
  document.getElementById("root")
);

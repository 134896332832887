import React, { useContext } from "react";
import styled from "styled-components";
import TransactionContext from "../../../../../Contexts/Transaction";
import Modal from "../../../../../Components/Modal";
import Close from "../../../../../assets/images/modal/close.svg";

const MobileHistory = () => {
  const checkHistory = useContext(TransactionContext);
  return (
    <Modal>
      <StyledWrapper>
        <StyleClose onClick={checkHistory.HandleClose}>
          <img src={Close} alt="flashift" />
        </StyleClose>
        <h4>History</h4>
        <StyledItems>
          {[...checkHistory.exchangeidlist]
            .reverse()
            .map((transactionitem, index) => {
              return (
                <StyledItem key={index}>
                  <span>{index + 1}</span>
                  <span
                    onClick={(event) => {
                      checkHistory.HandleCloseFinished();
                      checkHistory.HandleHistory(event);
                    }}
                  >
                    {transactionitem.exchangeitem}
                  </span>
                </StyledItem>
              );
            })}
        </StyledItems>
      </StyledWrapper>
    </Modal>
  );
};

const StyledWrapper = styled.div`
  padding: 10px;
  color: ${(props) => props.theme.color.ColorTwo};
`;
const StyledItems = styled.ul`
  height: calc(100vh - 180px);
  overflow: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b4c9f2;
    border-radius: 8px;
    border: 1px solid transparent;
  }
  @media (max-width: 1300px) {
    height: 280px;
  }
`;
const StyledItem = styled.li`
  display: flex;
  display: -ms-flexbox;
  justify-content: left;
  padding: 10px;
  cursor: pointer;
  &:hover {
    span {
      &:nth-child(1) {
        color: ${(props) => props.theme.color.ColorFive};
      }
      &:nth-child(2) {
        color: #f39c12;
      }
    }
  }
  span {
    &:nth-child(1) {
      color: #f39c12;
      margin-right: 13px;
    }
    &:nth-child(2) {
      color: ${(props) => props.theme.color.ColorFive};
    }
  }
`;
const StyleClose = styled.button`
  position: absolute;
  right: 15px;
  top: 20px;
`;

export default MobileHistory;

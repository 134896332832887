import React, { useState, useContext } from "react";
import TransactionContext from "../../Contexts/Transaction";
import MobileClose from "../../assets/images/modal/mobileclose.svg";
import Avatar from "../../assets/images/modal/avatar.svg";
import Walletcopy from "../../assets/images/modal/walletcopy.svg";
import Walletswap from "../../assets/images/modal/walletswap.svg";
import Walletbalance from "../../assets/images/modal/walletbalance.svg";
import Walletnetwork from "../../assets/images/modal/walletnetwork.svg";
import Wallet from "../../assets/images/modal/wallet.svg";
import Exit from "../../assets/images/modal/exit.svg";
import Modal from "../../Components/Modal";
import styled from "styled-components";

const addresslist = [
  {
    id: "1",
    address: "ac1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh",
  },
  {
    id: "2",
    address: "bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh",
  },
  {
    id: "3",
    address: "cc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh",
  },
  {
    id: "4",
    address: "dc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh",
  },
];
const MobileWalletModal = () => {
  const checkWalletModal = useContext(TransactionContext);
  const [showlist, SetShowList] = useState(false);
  const [addressitem, SetAddressItem] = useState("");

  const HandleSelectlist = (event) => {
    const newaddressitem = event.currentTarget?.innerHTML;
    SetAddressItem(newaddressitem);
    SetShowList(!showlist);
  };
  const HandleShowlist = () => {
    SetShowList(!showlist);
  };
  const HandleCopy = () => {
    const text = document.querySelector(".address_item")?.innerText;
    navigator.clipboard.writeText(text);
  };

  return (
    <Modal>
      <StyledHeader>
        <button onClick={checkWalletModal.HandleClose}>
          <img src={MobileClose} alt="flashift" />
        </button>
        <StyledInfo>
          <img src={Avatar} alt="flashift" />
          <div>
            <h5>Account_Name</h5>
            <p>
              <span onClick={HandleCopy}>
                <img src={Walletcopy} alt="flashift" />
              </span>
              <span>
                <img src={Walletswap} alt="flashift" />
              </span>
              <span onClick={checkWalletModal.HandleClose}>
                <img src={Exit} alt="flashift" />
              </span>
            </p>
          </div>
        </StyledInfo>
      </StyledHeader>
      <StyledContent>
        <ul>
          <li>
            <span>
              <img src={Walletbalance} alt="flashift" />
            </span>
            <span>balance : </span>
            <span>0.475 BNB</span>
          </li>
          <li>
            <span>
              <img src={Walletnetwork} alt="flashift" />
            </span>
            <span>network : </span>
            <span>binance smart chain</span>
          </li>
          <li>
            <span>
              <img src={Wallet} alt="flashift" />
            </span>
            <span>wallet : </span>
            <span>tron wallet</span>
          </li>
        </ul>
        <StyledSelect>
          <StyledaddressTitr onClick={HandleShowlist}>
            <span>abc</span>
            <span>Address :</span>
            <span className="address_item">
              {addressitem === ""
                ? "dc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh"
                : addressitem}
            </span>
          </StyledaddressTitr>
          {showlist ? (
            <StyledItems>
              {addresslist.map((newaddress) => {
                return (
                  <StyledItem onClick={HandleSelectlist} key={newaddress.id}>
                    {newaddress.address}
                  </StyledItem>
                );
              })}
            </StyledItems>
          ) : null}
        </StyledSelect>
      </StyledContent>
    </Modal>
  );
};

const StyledHeader = styled.div`
  padding: 15px;
  button {
    position: absolute;
    right: 15px;
    top: 15px;
  }
`;
const StyledInfo = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: left;
  h5 {
    font-weight: 700;
    margin-bottom: 10px;
  }
  img {
    margin-right: 20px;
  }
  span {
    cursor: pointer;
    width: 25px;
    margin: 5px 20px 5px 0;
    display: inline-block;
  }
`;
const StyledContent = styled.div`
  margin: 0 auto;
  padding: 0 10px;
  li {
    padding: 6px;
    img {
      margin-right: 5px;
      position: relative;
      top: 3px;
    }
    span {
      display: inline-block;
      text-align: left;
      &:first-child {
        width: 28px;
      }
      &:nth-child(2) {
        width: 73px;
      }
    }
  }
`;
const StyledSelect = styled.div`
  position: relative;
  margin-bottom: 20px;
`;
const StyledaddressTitr = styled.div`
  color: ${(props) => props.theme.color.ColorTwo};
  background: rgba(179, 188, 202, 0.42) url(./assets/images/walletarrow.svg)
    no-repeat right 12px center;
  border-radius: 8px;
  padding: 0px 15px 0 10px;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: left;
  cursor: pointer;
  width: 465px;
  max-width: 100%;
  margin: 10px 0;
  word-break: break-all;
  hyphens: auto;
  span {
    padding: 10px 5px;
    &:first-child {
      color: ${(props) => props.theme.color.ColorEight};
    }
  }
  @media (max-width: 530px) {
    span {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-width: 415px) {
    span {
      padding: 2px 5px;
    }
  }
`;
const StyledItems = styled.ul`
  background: rgba(179, 188, 202, 0.6);
  height: 200px;
  overflow: auto;
  position: absolute;
  width: 465px;
  max-width: 100%;
  left: 0;
  z-index: 1;
  top: 46px;
  padding: 10px 0;
  scrollbar-width: thin;
  scrollbar-color: #787b86 #c2c2c2;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #787b86;
    border-radius: 8px;
    border: 1px solid #ffffff;
  }
`;
const StyledItem = styled.li`
  padding: 3px 10px;
  color: ${(props) => props.theme.color.ColorOne};
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
`;
export default MobileWalletModal;

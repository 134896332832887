import React, { useContext } from "react";
import styled from "styled-components";
import TransactionContext from "../../../../../Contexts/Transaction";
import Modal from "../../../../../Components/Modal";
import Close from "../../../../../assets/images/modal/close.svg";

const MobileSwapTracker = () => {
  const checkMobileSwapTracker = useContext(TransactionContext);
  return (
    <Modal>
      <StyledWrapper>
        <StyleClose onClick={checkMobileSwapTracker.HandleClose}>
          <img src={Close} alt="flashift" />
        </StyleClose>
        <StyledWrapper>
          <p>Exchange ID</p>
          <StyledInput>
            <input
              type="text"
              placeholder="Enter Your Exchange ID"
              value={checkMobileSwapTracker.searchid}
              onChange={checkMobileSwapTracker.HandleSearchId}
              required
            />
          </StyledInput>
          {checkMobileSwapTracker.exchangemessage ? (
            <StyledMessage> Please enter exchangeID</StyledMessage>
          ) : null}
          <StyledButton>
            <button
              className="button"
              onClick={checkMobileSwapTracker.HandleTrack}
            >
              Track Your Swap
            </button>
          </StyledButton>
        </StyledWrapper>
      </StyledWrapper>
    </Modal>
  );
};

const StyledWrapper = styled.div`
  padding: 30px 15px 40px 15px;
  color: ${(props) => props.theme.color.ColorTwo};
  p {
    margin-bottom: 15px;
  }
`;
const StyledInput = styled.div`
  input {
    border: 2px solid #f39c12;
    color: #f39c12;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 100%;
    height: 37px;
    padding: 10px;
  }
`;
const StyledButton = styled.div`
  margin-top: 15px;
  z-index: 1;
  position: relative;
  button {
    background: #f39c12;
    color: #ffffff;
    border-radius: 8px;
    font-size: 1.1rem;
    width: 100%;
    height: 37px;
  }
`;
const StyledMessage = styled.h6`
  color: ${(props) => props.theme.color.ColorTwelve};
`;
const StyleClose = styled.button`
  position: absolute;
  right: 15px;
  top: 20px;
`;
export default MobileSwapTracker;

import React, { useContext } from "react";
import styled from "styled-components";
import Card from "../../../../Components/Card";
import CardTitle from "../../../../Components/CardTitle";
import TransactionContext from "../../../../Contexts/Transaction";

const SwapTracker = () => {
  const checkSwapTracker = useContext(TransactionContext);
  return (
    <Card>
      <CardTitle>SwapTracker</CardTitle>
      <StyledWrapper>
        <p>Exchange ID</p>
        <StyledInput>
          <input
            type="text"
            placeholder="Enter Your Exchange ID"
            value={checkSwapTracker.searchid}
            onChange={checkSwapTracker.HandleSearchId}
            required
          />
        </StyledInput>
        {checkSwapTracker.exchangemessage ? (
          <StyledMessage> Please enter exchangeID</StyledMessage>
        ) : null}
        <StyledButton>
          <button className="button" onClick={checkSwapTracker.HandleTrack}>
            Track Your Swap
          </button>
        </StyledButton>
      </StyledWrapper>
    </Card>
  );
};

const StyledWrapper = styled.div`
  padding: 30px 15px 40px 15px;
  color: ${(props) => props.theme.color.ColorTwo};
  p {
    margin-bottom: 15px;
  }
`;
const StyledInput = styled.div`
  input {
    border: 2px solid ${(props) => props.theme.color.ColorEight};
    color: ${(props) => props.theme.color.ColorEight};
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: 100%;
    height: 37px;
    padding: 10px;
  }
`;
const StyledButton = styled.div`
  margin-top: 15px;
  z-index: 1;
  position: relative;
  button {
    background: ${(props) => props.theme.color.ColorEight};
    color: #ffffff;
    border-radius: 8px;
    font-size: 1.1rem;
    width: 100%;
    height: 37px;
  }
`;
const StyledMessage = styled.h6`
  color: ${(props) => props.theme.color.ColorTwelve};
`;
export default SwapTracker;

import { createContext } from 'react'

const ThemeContext = createContext({
  toggleTheme: () => null ,
  isDark: null,

})

export default ThemeContext;





import { 
  ColorOne,
  ColorTwo,
  ColorThree,
  ColorFour,
  ColorFive,
  ColorSix,
  ColorSeven,
  ColorEight,
  ColorNine,
  ColorTen,
  ColorEleven,
  ColorTwelve,
  ColorThirteen,
  RadiusOne,
  RadiusTwo, 
  RadiusThree,
  ShadowOne,
 } from './light'

const light = {
  color: {
    ColorOne,
    ColorTwo,
    ColorThree,
    ColorFour,
    ColorFive,
    ColorSix,
    ColorSeven,
    ColorEight,
    ColorNine,
    ColorTen,
    ColorEleven,
    ColorTwelve,
    ColorThirteen,
    ShadowOne
  }
  ,
  radius:{
    RadiusOne,
    RadiusTwo,
    RadiusThree
  }
  ,
  shadow:{
    ShadowOne,
  }
}
export default light

import React, { useContext, useState, useEffect } from "react";
import { AutoSizer, List } from "react-virtualized";
import TransactionContext from "../../../../Contexts/Transaction";
import styled from "styled-components";
import coins from "../../../../assets/Coinlist.json";
import ModalCoins from "../../../../Components/ModalCoins";
import Close from "../../../../assets/images/modal/close.svg";

const CoinsFrom = () => {
  const checkCurrencyFrom = useContext(TransactionContext);
  let filterCoins = coins.filter((item) => item.memo === true);
  const [currencylistfrom, SetCurrencylistfrom] = useState(filterCoins);
  const HandleSelectfrom = (event) => {
    document.querySelector(".background_container").style.filter = "blur(0)";
    const newexchangenamefrom =
      event.currentTarget.querySelector(".exchangename_item")?.innerHTML;
    const newexchangesymbolfrom = event.currentTarget.querySelector(
      ".exchangesymbol_item"
    )?.innerHTML;
    const newexchangefullnamefrom = event.currentTarget.querySelector(
      ".exchangefullname_item"
    )?.innerHTML;
    const newexchangeiconfrom =
      event.currentTarget.querySelector(".exchangeicon_item")?.innerHTML;
    const newnetworkfrom =
      event.currentTarget.querySelector(".network_item")?.innerHTML;
    checkCurrencyFrom.SetexchangeNamefrom(newexchangenamefrom);
    checkCurrencyFrom.SetexchangeSymbolfrom(newexchangesymbolfrom);
    checkCurrencyFrom.SetexchangeFullnamefrom(newexchangefullnamefrom);
    checkCurrencyFrom.SetexchangeIconfrom(newexchangeiconfrom);
    checkCurrencyFrom.Setcefishowfrom(!checkCurrencyFrom.cefishowfrom);
    checkCurrencyFrom.SetNetworkFrom(newnetworkfrom);
    checkCurrencyFrom.SetLoading(true);
    checkCurrencyFrom.SetSelect(0);
  };

  useEffect(() => {
    if (checkCurrencyFrom.search === "") {
      SetCurrencylistfrom(coins);
    } else {
      const currencyitemone = [...coins].filter(
        (o) =>
          o.symbol.toUpperCase().includes(checkCurrencyFrom.search) ||
          o.fullname
            .toUpperCase()
            .includes(checkCurrencyFrom.search.toUpperCase())
      );
      SetCurrencylistfrom(currencyitemone);
    }
  }, [
    checkCurrencyFrom.search,
    coins,
    checkCurrencyFrom.exchangeexplorerfrom,
    checkCurrencyFrom.exchangeexplorerto,
  ]);

  const rowRenderer = function ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) {
    return (
      <li onClick={HandleSelectfrom} key={key} style={style}>
        <p>
          <div>
            <span className="exchangeicon_item">
              <em className={"icon-" + currencylistfrom[index].name}></em>
            </span>
            <span className="captal exchangename_item">
              {currencylistfrom[index].name}
            </span>
            <span className="captal exchangesymbol_item">
              {currencylistfrom[index].symbol}
            </span>
          </div>
          <span className="exchangefullname_item">
            {currencylistfrom[index].fullname}
          </span>
        </p>
        {currencylistfrom[index].network && (
          <small
            style={{ border: "1px solid #F39C12", color: "#F39C12" }}
            className={
              currencylistfrom[index].network
                ? "network_item network_active"
                : "network_item"
            }
          >
            {currencylistfrom[index].network
              ? currencylistfrom[index].network
              : null}
          </small>
        )}
      </li>
    );
  };

  return (
    <ModalCoins>
      <StyledcryptoInfo>
        <StyleClose onClick={checkCurrencyFrom.HandleClose}>
          <img src={Close} alt="flashift" />
        </StyleClose>
        <h4>Select a token</h4>
        <input
          autoFocus
          className="search"
          type="text"
          placeholder="search ..."
          value={checkCurrencyFrom.search}
          onChange={checkCurrencyFrom.HandleSearch}
        />
        <ul>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowCount={currencylistfrom.length}
                rowHeight={60}
                rowRenderer={rowRenderer}
                scrollToIndex={0}
              />
            )}
          </AutoSizer>
        </ul>
      </StyledcryptoInfo>
    </ModalCoins>
  );
};

export default CoinsFrom;
const StyledcryptoInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 98%;
  overflow: hidden;
  position: relative;
  .exchangesymbol_item {
    display: none;
  }
  .exchangeicon_item {
    em {
      margin-right: 5px;
    }
  }
  h4 {
    color: #f39c12;
  }
  .ReactVirtualized__List {
    height: 100%;
    overflow: auto;
    margin: 10px auto 5px auto;
    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: #787b86 #c2c2c2;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #c2c2c2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #787b86;
      border-radius: 8px;
      border: 1px solid #ffffff;
    }
  }
  ul {
    flex: 1;
    margin-bottom: 10px;
  }
  li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 7px 7px 2px 7px;
    cursor: pointer;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    transition: all 200ms;
    &:last-child {
      border-bottom: none;
    }
    .network_active {
      border-radius: 5px;
      height: 20px;
      min-width: 40px;
      text-align: center;
      padding: 1px 5px;
      position: relative;
      top: 13px;
    }
    em {
      font-size: 2rem;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
    p {
      div {
        display: flex;
        display: -ms-flexbox;
        flex-wrap: erap;
        -ms-flex-wrap: wrap;
        font-size: 1.1rem;
        height: 30px;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
        span {
          &:last-child {
            opacity: 1;
            position: relative;
            left: 6px;
            top: 3px;
          }
        }
      }
    }
    img {
      width: 21px;
    }

    &:hover {
      background: ${(props) => props.theme.color.ColorThree};
      em {
        color: ${(props) => props.theme.color.ColorEight};
      }
    }
    span {
      i {
        font-size: 1.4rem;
        margin-right: 5px;
        position: relative;
        top: 3px;
      }
      &:last-child {
        opacity: 0.5;
      }
    }
    > span {
      &:last-child {
        position: relative;
        top: 3px;
        width: 32px;
        height: 17px;
        text-align: center;
        margin-left: 6px;
        border-radius: ${(props) => props.theme.radius.RadiusTwo};
        padding-top: 1px;
        font-size: 0.8rem;
      }
    }
  }
  input {
    color: ${(props) => props.theme.color.ColorTwo};
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    padding: 13px 10px;
    border-radius: 8px;
    margin-top: 10px;
    font-size: 1.1rem;
  }
`;
const StyleClose = styled.button`
  position: absolute;
  right: 0;
  top: 0;
`;

import React, { useContext, useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransactionContext from "../../../../../Contexts/Transaction";
import Close from "../../../../../assets/images/modal/close.svg";
import MobileClose from "../../../../../assets/images/modal/mobileclose.svg";
import StepFour from "../../../../../assets/images/modal/step4.svg";
import Copy from "../../../../../assets/images/modal/copy.svg";
import FinishedIcon from "../../../../../assets/images/modal/finishedicon.svg";
import Overdue from "../../../../../assets/images/modal/overdue.png";
import Loading from "../../../../../assets/images/loading.svg";
import TrustWallet from "../../../../../assets/images/modal/trustwallet.svg";
import MediaQuery from "react-responsive";
import coins from "../../../../../assets/Coinlist.json";

const ShowCopyToClipboardToast = () => {
  toast.success("Copied to the clipboard", {
    position: "bottom-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const Finalizing = () => {
  const checkFinalizing = useContext(TransactionContext);
  const [currencylistfrom, SetCurrencylistfrom] = useState(coins);
  const [explorerfrom, SetExplorerfrom] = useState("");
  const [explorerto, SetExplorerto] = useState("");

  useEffect(() => {
    if ((checkFinalizing.hashfrom !== "") & (checkFinalizing.hashto === "")) {
      const textfrom = document
        .querySelector(".final_coinfrom")
        .innerText.toLowerCase();
      const indexfrom = currencylistfrom.findIndex(
        (obj) => obj.name === textfrom
      );
      const newexplorerfrom = currencylistfrom[indexfrom].explorer;
      SetExplorerfrom(newexplorerfrom);
    }
    if ((checkFinalizing.hashfrom !== "") & (checkFinalizing.hashto !== "")) {
      const textfrom = document
        .querySelector(".final_coinfrom")
        .innerText.toLowerCase();
      const indexfrom = currencylistfrom.findIndex(
        (obj) => obj.name === textfrom
      );
      const newexplorerfrom = currencylistfrom[indexfrom].explorer;
      SetExplorerfrom(newexplorerfrom);
      const textto = document
        .querySelector(".final_cointo")
        .innerText.toLowerCase();
      const indexto = currencylistfrom.findIndex((obj) => obj.name === textto);
      const newexplorerto = currencylistfrom[indexto].explorer;
      SetExplorerto(newexplorerto);
    }

    if ((checkFinalizing.hashfrom === "") & (checkFinalizing.hashto !== "")) {
      const textto = document
        .querySelector(".final_cointo")
        .innerText.toLowerCase();
      const indexto = currencylistfrom.findIndex((obj) => obj.name === textto);
      const newexplorerto = currencylistfrom[indexto].explorer;
      SetExplorerto(newexplorerto);
    }
  }, [
    checkFinalizing.hashfrom,
    checkFinalizing.exchangenamefromfinal,
    checkFinalizing.addressfrom,
    checkFinalizing.hashto,
    explorerfrom,
    explorerto,
  ]);

  return (
    <div>
      <ToastContainer />
      {(checkFinalizing.hashfrom === "") & (checkFinalizing.hashto === "") ? (
        <>
          <StyledHeader>
            <button onClick={checkFinalizing.HandleCloseFinished}>
              <img src={Close} alt="flashift" />
              <img src={MobileClose} alt="flashift" />
            </button>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <p>
                <span></span>
                <br />4
              </p>
            </div>
          </StyledHeader>
          {checkFinalizing?.exchangeData ? (
            <StyledContent>
              <StyledLeft>
                <h2>
                  Finalizing the transaction
                  {checkFinalizing.statusmessage !== "Server Error" ? (
                    <p>
                      Status : <span>{checkFinalizing.status}</span>
                      {checkFinalizing.status === "overdue" ? (
                        <img
                          className="overdue_icon"
                          src={Overdue}
                          alt="flashift"
                        />
                      ) : (
                        <StyledLoading>
                          <img src={Loading} alt="flashift" />
                        </StyledLoading>
                      )}
                    </p>
                  ) : null}
                </h2>
                {checkFinalizing.statusmessage === "Server Error" ? (
                  <p className="error_message">
                    This Exchange ID was not found !
                    <br />
                    Please enter the valid Exchange ID
                  </p>
                ) : (
                  <>
                    <StyledItemBottom className="top_part">
                      <ul>
                        <li>
                          <span>Sending:&nbsp;</span>
                          <br />
                          <span className="captal">
                            {checkFinalizing.cefivaluefromfinal}{" "}
                            <span className="final_coinfrom">
                              {checkFinalizing.exchangenamefromfinal}
                            </span>
                          </span>
                          <span
                            className={
                              checkFinalizing.networkfromfinal
                                ? "networkfromfinal_item"
                                : null
                            }
                          >
                            {checkFinalizing.networkfromfinal}
                          </span>
                        </li>
                        <li>
                          <span>Getting:&nbsp;</span>
                          <br />
                          <span className="captal">
                            {checkFinalizing.cefivaluetofinal}{" "}
                            {checkFinalizing.exchangenametofinal}
                          </span>
                          <span
                            className={
                              checkFinalizing.networktofinal
                                ? "networktofinal_item"
                                : null
                            }
                          >
                            {checkFinalizing.networktofinal}
                          </span>
                        </li>
                        <li>
                          <span>Service:&nbsp;</span>
                          <br />
                          <span>{checkFinalizing.servicefinal}</span>
                        </li>
                        <li>
                          <span>Exchange Type:&nbsp;</span>
                          <br />
                          <span>{checkFinalizing.exchangetypefinal}</span>
                        </li>
                        <MediaQuery maxDeviceWidth={768}>
                          {checkFinalizing.status !== "overdue" ? (
                            <li
                              className="button"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "38px",
                              }}
                            >
                              <a
                                className={
                                  (checkFinalizing.cefivaluefromfinal === "") &
                                  (checkFinalizing.addressfrom === "") &
                                  (checkFinalizing.exchangenamefromfinal === "")
                                    ? "deactive"
                                    : null
                                }
                                href={`https://link.trustwallet.com/send?asset=c${checkFinalizing.networkidfrom}_t${checkFinalizing.contractfrom}&address=${checkFinalizing.addressfrom}&amount=${checkFinalizing.cefivaluefromfinal}&memo=${checkFinalizing.memofrom}`}
                                target={"_blank"}
                              >
                                <img
                                  src={TrustWallet}
                                  width={"8%"}
                                  style={{ position: "relative", top: "7px" }}
                                />
                                Pay with Trustwallet
                              </a>
                            </li>
                          ) : null}
                        </MediaQuery>
                      </ul>
                    </StyledItemBottom>
                    {checkFinalizing.status !== "overdue" ? (
                      <StyledItemBottom>
                        <StyledInfo className="value_item">
                          <h6>Exchange ID:</h6>
                          <h5>
                            <p>
                              {checkFinalizing.exchangeid}
                              <CopyToClipboard
                                text={checkFinalizing.exchangeid}
                                onCopy={() => ShowCopyToClipboardToast()}
                              >
                                <span>
                                  <img src={Copy} alt="flashift" />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </h5>
                        </StyledInfo>
                        <StyledInfo className="value_item">
                          <h6>
                            Please send{" "}
                            <span>{checkFinalizing.cefivaluefromfinal}</span>
                            <span className="captal">
                              {" "}
                              {checkFinalizing.exchangenamefromfinal}
                            </span>{" "}
                            to this address:
                          </h6>
                          <h5>
                            <p>
                              {checkFinalizing.addressfrom}
                              <CopyToClipboard
                                text={checkFinalizing.addressfrom}
                                onCopy={() => ShowCopyToClipboardToast()}
                              >
                                <span>
                                  <img src={Copy} alt="flashift" />
                                </span>
                              </CopyToClipboard>
                            </p>
                          </h5>
                        </StyledInfo>
                        {checkFinalizing.memofrom !== "" ? (
                          <StyledInfo className="value_item">
                            <h6>EXTRA ID:</h6>
                            <h5>
                              <p>
                                {checkFinalizing.memofrom}
                                <span className="required">(Required!)</span>
                                <CopyToClipboard
                                  text={checkFinalizing.memofrom}
                                  onCopy={() => ShowCopyToClipboardToast()}
                                >
                                  <span>
                                    <img src={Copy} alt="flashift" />
                                  </span>
                                </CopyToClipboard>
                              </p>
                            </h5>
                          </StyledInfo>
                        ) : null}
                      </StyledItemBottom>
                    ) : null}
                  </>
                )}
              </StyledLeft>
              <StyledRight>
                <StyledTooltipPoint id="pointone">
                  <StyledTooltipStep id="popoverone">
                    <div>
                      <p>
                        <span>Step 1 </span>Select rate
                      </p>
                      <p>
                        Select the exchange service which meets your
                        requirements
                      </p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>
                <StyledTooltipPoint id="pointtwo">
                  <StyledTooltipStep id="popovertwo">
                    <div>
                      <p>
                        <span> Step 2</span> Enter address
                      </p>
                      <p>Enter your destination address and EXTRA ID</p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>

                <StyledTooltipPoint id="pointthree">
                  <StyledTooltipStep id="popoverthree">
                    <div>
                      <p>
                        <span> Step 3</span> Conform
                      </p>
                      <p>Reveiw everything before conforming</p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>
                <StyledTooltipPoint id="pointfour">
                  <StyledTooltipStep id="popoverfour">
                    <div>
                      <p>
                        <span>Step 4</span> Receive
                      </p>
                      <p>You will get your coins in a few minutes</p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>
                <p>
                  <img className="step_pic" src={StepFour} alt="flashift" />
                </p>
                {(checkFinalizing.status !== "overdue") &
                (checkFinalizing.addressfrom !== "") &
                (checkFinalizing.statusmessage !== "Server Error") ? (
                  <StyledQrcode>
                    <h5>
                      You can also
                      <br /> scan this
                    </h5>
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${checkFinalizing.addressfrom}`}
                      alt="flashift"
                    />
                  </StyledQrcode>
                ) : null}
              </StyledRight>
            </StyledContent>
          ) : !checkFinalizing.statusmessage ? (
            <StyledLargLoading>
              <div class="loader">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
              </div>
            </StyledLargLoading>
          ) : (
            <p className="error">{checkFinalizing.statusmessage}</p>
          )}
        </>
      ) : null}
      {(checkFinalizing.hashfrom !== "") & (checkFinalizing.hashto === "") ? (
        <>
          <StyledHeader>
            <button onClick={checkFinalizing.HandleCloseFinished}>
              <img src={Close} alt="flashift" />
              <img src={MobileClose} alt="flashift" />
            </button>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <p>
                <span></span>
                <br />4
              </p>
            </div>
          </StyledHeader>
          {checkFinalizing?.exchangeData ? (
            <StyledContent>
              <StyledLeft>
                <h2>
                  Finalizing the transaction
                  <p>
                    Status:
                    <span>
                      {checkFinalizing.status}
                      <StyledLoading>
                        <img src={Loading} alt="flashift" />
                      </StyledLoading>
                    </span>
                  </p>
                </h2>
                <StyledItemBottom className="top_part">
                  <ul>
                    <li>
                      <span>Sending:&nbsp;</span>
                      <br />
                      <span className="captal">
                        {checkFinalizing.cefivaluefromfinal}{" "}
                        <span className="final_coinfrom">
                          {checkFinalizing.exchangenamefromfinal}
                        </span>
                      </span>
                      <span
                        className={
                          checkFinalizing.networkfromfinal
                            ? "networkfromfinal_item"
                            : null
                        }
                      >
                        {checkFinalizing.networkfromfinal}
                      </span>
                    </li>
                    <li>
                      <span>Getting:&nbsp;</span>
                      <br />
                      <span className="captal">
                        {checkFinalizing.cefivaluetofinal}{" "}
                        {checkFinalizing.exchangenametofinal}
                      </span>
                      <span
                        className={
                          checkFinalizing.networktofinal
                            ? "networktofinal_item"
                            : null
                        }
                      >
                        {checkFinalizing.networktofinal}
                      </span>
                    </li>
                    <li>
                      <span>Service:&nbsp;</span>
                      <br />
                      <span>{checkFinalizing.servicefinal}</span>
                    </li>
                    <li>
                      <span>Exchange Type: </span>
                      <br />
                      <span>{checkFinalizing.exchangetypefinal}</span>
                    </li>
                  </ul>
                </StyledItemBottom>
                <StyledItemBottom>
                  <StyledInfo className="value_item">
                    <h6>Exchange ID:</h6>
                    <h5>
                      <p>
                        {checkFinalizing.exchangeid}
                        <CopyToClipboard
                          text={checkFinalizing.exchangeid}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  <StyledInfo className="value_item">
                    <h6>
                      You sent <span>{checkFinalizing.cefivaluefromfinal}</span>
                      <span className="captal">
                        {" "}
                        {checkFinalizing.exchangenamefromfinal}
                      </span>{" "}
                      to this address:
                    </h6>
                    <h5>
                      <p>
                        {checkFinalizing.addressfrom}
                        <CopyToClipboard
                          text={checkFinalizing.addressfrom}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  {checkFinalizing.memofrom ? (
                    <StyledInfo className="value_item">
                      <h6>EXTRA ID:</h6>
                      <h5>
                        <p>
                          {checkFinalizing.memofrom}
                          <CopyToClipboard
                            text={checkFinalizing.memofrom}
                            onCopy={() => ShowCopyToClipboardToast()}
                          >
                            <span>
                              <img src={Copy} alt="flashift" />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </h5>
                    </StyledInfo>
                  ) : null}
                  <StyledInfo className="value_item">
                    <h6>Hash:</h6>
                    <h5>
                      <p>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${explorerfrom}${checkFinalizing.hashfrom}`}
                        >
                          {checkFinalizing.hashfrom}
                        </a>
                        <CopyToClipboard
                          text={checkFinalizing.hashfrom}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                </StyledItemBottom>
                <StyledButton>
                  <button onClick={checkFinalizing.HandleModalRedo}>
                    Redo This Exchange
                  </button>
                </StyledButton>
              </StyledLeft>
              <StyledRight>
                <StyledTooltipPoint id="pointone">
                  <StyledTooltipStep id="popoverone">
                    <div>
                      <p>
                        <span>Step 1 </span>Select rate
                      </p>
                      <p>
                        Select the exchange service which meets your
                        requirements
                      </p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>
                <StyledTooltipPoint id="pointtwo">
                  <StyledTooltipStep id="popovertwo">
                    <div>
                      <p>
                        <span> Step 2</span> Enter address
                      </p>
                      <p>Enter your destination address and EXTRA ID</p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>

                <StyledTooltipPoint id="pointthree">
                  <StyledTooltipStep id="popoverthree">
                    <div>
                      <p>
                        <span> Step 3</span> Conform
                      </p>
                      <p>Reveiw everything before conforming</p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>
                <StyledTooltipPoint id="pointfour">
                  <StyledTooltipStep id="popoverfour">
                    <div>
                      <p>
                        <span>Step 4</span> Receive
                      </p>
                      <p>You will get your coins in a few minutes</p>
                    </div>
                  </StyledTooltipStep>
                </StyledTooltipPoint>
                <p>
                  <img src={StepFour} alt="flashift" />
                </p>
              </StyledRight>
            </StyledContent>
          ) : !checkFinalizing.statusmessage ? (
            <StyledLargLoading>
              <div class="loader">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
              </div>
            </StyledLargLoading>
          ) : (
            <p className="error">{checkFinalizing.statusmessage}</p>
          )}
        </>
      ) : null}
      {(checkFinalizing.hashfrom !== "") & (checkFinalizing.hashto !== "") ? (
        <>
          <StyledHeader>
            <button onClick={checkFinalizing.HandleCloseFinished}>
              <img src={Close} alt="flashift" />
              <img src={MobileClose} alt="flashift" />
            </button>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <p>
                <span></span>
                <br />4
              </p>
            </div>
          </StyledHeader>
          {checkFinalizing?.exchangeData ? (
            <StyledContent>
              <StyledLeft className="finalleft_part">
                <h2>
                  Finalizing the transaction
                  <p>
                    Status:
                    <span>
                      {checkFinalizing.status}
                      <img src={FinishedIcon} alt="flashift" />
                    </span>
                  </p>
                  <div className="mobile_starBox">
                    <p>rate us on Trustpilot</p>
                    <a
                      className="starBox"
                      href="https://www.trustpilot.com/evaluate/flashift.app"
                      target="_blank"
                    >
                      <span id="starOne">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starTwo">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starThree">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starFour">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starFive">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </h2>
                <StyledItemBottom className="top_part">
                  <ul>
                    <li>
                      <span>Sending:&nbsp;</span>
                      <br />
                      <span className="captal">
                        {checkFinalizing.cefivaluefromfinal}{" "}
                        <span className=" final_coinfrom">
                          {checkFinalizing.exchangenamefromfinal}{" "}
                        </span>
                      </span>
                      <span
                        className={
                          checkFinalizing.networkfromfinal
                            ? "networkfromfinal_item"
                            : null
                        }
                      >
                        {checkFinalizing.networkfromfinal}
                      </span>
                    </li>
                    <li>
                      <span>Getting:&nbsp;</span>
                      <br />
                      <span className="captal">
                        {checkFinalizing.cefivaluetofinal}{" "}
                        <span className=" final_cointo">
                          {checkFinalizing.exchangenametofinal}
                        </span>{" "}
                      </span>
                      <span
                        className={
                          checkFinalizing.networktofinal
                            ? "networktofinal_item"
                            : null
                        }
                      >
                        {checkFinalizing.networktofinal}
                      </span>
                    </li>
                    <li>
                      <span>Service:&nbsp;</span>
                      <br />
                      <span>{checkFinalizing.servicefinal}</span>
                    </li>
                    <li>
                      <span>Exchange Type:&nbsp;</span>
                      <br />
                      <span>{checkFinalizing.exchangetypefinal}</span>
                    </li>
                  </ul>
                </StyledItemBottom>
                <StyledItemBottom>
                  <StyledInfo className="value_item">
                    <h6>Exchange ID:</h6>
                    <h5>
                      <p>
                        {checkFinalizing.exchangeid}
                        <CopyToClipboard
                          text={checkFinalizing.exchangeid}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  <StyledInfo className="value_item">
                    <h6>
                      You sent <span>{checkFinalizing.cefivaluefromfinal}</span>
                      <span className="captal">
                        {" "}
                        {checkFinalizing.exchangenamefromfinal}
                      </span>{" "}
                      to this address:
                    </h6>
                    <h5>
                      <p>
                        {checkFinalizing.addressfrom}
                        <CopyToClipboard
                          text={checkFinalizing.addressfrom}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  {checkFinalizing.memofrom ? (
                    <StyledInfo className="value_item">
                      <h6>EXTRA ID:</h6>
                      <h5>
                        <p>
                          {checkFinalizing.memofrom}
                          <CopyToClipboard
                            text={checkFinalizing.memofrom}
                            onCopy={() => ShowCopyToClipboardToast()}
                          >
                            <span>
                              <img src={Copy} alt="flashift" />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </h5>
                    </StyledInfo>
                  ) : null}
                  <StyledInfo className="value_item">
                    <h6>Hash:</h6>
                    <h5>
                      <p>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${explorerfrom}${checkFinalizing.hashfrom}`}
                        >
                          {checkFinalizing.hashfrom}
                        </a>
                        <CopyToClipboard
                          text={checkFinalizing.hashfrom}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                </StyledItemBottom>
                <StyledButton>
                  <button onClick={checkFinalizing.HandleModalRedo}>
                    Redo This Exchange
                  </button>
                </StyledButton>
              </StyledLeft>
              <StyledRight className="finalright_part" id="finalright_part">
                <StyledItem>
                  <h4>Congrats</h4>
                  <h1>Transaction Successful</h1>
                  <p>rate us on Trustpilot</p>
                  <div>
                    <a
                      className="starBox"
                      href="https://www.trustpilot.com/evaluate/flashift.app"
                      target="_blank"
                    >
                      <span id="starOne">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starTwo">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starThree">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starFour">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starFive">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </StyledItem>
                <StyledItemBottom>
                  <StyledInfo className="value_item">
                    <h6>
                      Recipient{" "}
                      <span className="captal">
                        {checkFinalizing.exchangenametofinal}
                      </span>{" "}
                      address:
                    </h6>
                    <h5>
                      <p>
                        {checkFinalizing.address}
                        <CopyToClipboard
                          text={checkFinalizing.address}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  {checkFinalizing.memo ? (
                    <StyledInfo className="value_item">
                      <h6>EXTRA ID:</h6>
                      <h5>
                        <p>
                          {checkFinalizing.memo}
                          <CopyToClipboard
                            text={checkFinalizing.memo}
                            onCopy={() => ShowCopyToClipboardToast()}
                          >
                            <span>
                              <img src={Copy} alt="flashift" />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </h5>
                    </StyledInfo>
                  ) : null}
                  <StyledInfo className="value_item">
                    <h6>Hash:</h6>
                    <h5>
                      <p>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${explorerto}${checkFinalizing.hashto}`}
                        >
                          {checkFinalizing.hashto}
                        </a>
                        <CopyToClipboard
                          text={checkFinalizing.hashto}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                </StyledItemBottom>
              </StyledRight>
            </StyledContent>
          ) : !checkFinalizing.statusmessage ? (
            <StyledLargLoading>
              <div class="loader">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
              </div>
            </StyledLargLoading>
          ) : (
            <p className="error">{checkFinalizing.statusmessage}</p>
          )}
        </>
      ) : null}

      {(checkFinalizing.hashfrom === "") & (checkFinalizing.hashto !== "") ? (
        <>
          <StyledHeader>
            <button onClick={checkFinalizing.HandleCloseFinished}>
              <img src={Close} alt="flashift" />
              <img src={MobileClose} alt="flashift" />
            </button>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <p>
                <span></span>
                <br />4
              </p>
            </div>
          </StyledHeader>
          {checkFinalizing?.exchangeData ? (
            <StyledContent>
              <StyledLeft className="finalleft_part">
                <h2>
                  Finalizing the transaction
                  <p>
                    Status:
                    <span>
                      {checkFinalizing.status}
                      <img src={FinishedIcon} alt="flashift" />
                    </span>
                  </p>
                </h2>
                <StyledItemBottom className="top_part">
                  <ul>
                    <li>
                      <span>Sending:&nbsp;</span>
                      <br />
                      <span className="captal">
                        {checkFinalizing.cefivaluefromfinal}{" "}
                        <span className=" final_coinfrom">
                          {checkFinalizing.exchangenamefromfinal}{" "}
                        </span>
                      </span>
                      <span
                        className={
                          checkFinalizing.networkfromfinal
                            ? "networkfromfinal_item"
                            : null
                        }
                      >
                        {checkFinalizing.networkfromfinal}
                      </span>
                    </li>
                    <li>
                      <span>Getting:&nbsp;</span>
                      <br />
                      <span className="captal">
                        {checkFinalizing.cefivaluetofinal}{" "}
                        <span className=" final_cointo">
                          {checkFinalizing.exchangenametofinal}
                        </span>{" "}
                      </span>
                      <span
                        className={
                          checkFinalizing.networktofinal
                            ? "networktofinal_item"
                            : null
                        }
                      >
                        {checkFinalizing.networktofinal}
                      </span>
                    </li>
                    <li>
                      <span>Service:&nbsp;</span>
                      <br />
                      <span>{checkFinalizing.servicefinal}</span>
                    </li>
                    <li>
                      <span>Exchange Type:&nbsp;</span>
                      <br />
                      <span>{checkFinalizing.exchangetypefinal}</span>
                    </li>
                  </ul>
                </StyledItemBottom>
                <StyledItemBottom>
                  <StyledInfo className="value_item">
                    <h6>Exchange ID:</h6>
                    <h5>
                      <p>
                        {checkFinalizing.exchangeid}
                        <CopyToClipboard
                          text={checkFinalizing.exchangeid}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  <StyledInfo className="value_item">
                    <h6>
                      You sent <span>{checkFinalizing.cefivaluefromfinal}</span>
                      <span className="captal">
                        {" "}
                        {checkFinalizing.exchangenamefromfinal}
                      </span>{" "}
                      to this address:
                    </h6>
                    <h5>
                      <p>
                        {checkFinalizing.addressfrom}
                        <CopyToClipboard
                          text={checkFinalizing.addressfrom}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  {checkFinalizing.memofrom ? (
                    <StyledInfo className="value_item">
                      <h6>EXTRA ID:</h6>
                      <h5>
                        <p>
                          {checkFinalizing.memofrom}
                          <CopyToClipboard
                            text={checkFinalizing.memofrom}
                            onCopy={() => ShowCopyToClipboardToast()}
                          >
                            <span>
                              <img src={Copy} alt="flashift" />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </h5>
                    </StyledInfo>
                  ) : null}
                </StyledItemBottom>
                <StyledButton>
                  <button onClick={checkFinalizing.HandleModalRedo}>
                    Redo This Exchange
                  </button>
                </StyledButton>
              </StyledLeft>
              <StyledRight className="finalright_part">
                <StyledItem>
                  <h4>Congrats</h4>
                  <h1>Transaction Successful</h1>
                  <p>rate us on Trustpilot</p>
                  <div>
                    <a
                      className="starBox"
                      href="https://www.trustpilot.com/evaluate/flashift.app"
                      target="_blank"
                    >
                      <span id="starOne">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starTwo">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starThree">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starFour">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                      <span id="starFive">
                        <svg
                          viewBox="0 0 16 16"
                          fill="#f39c12"
                          class="icon_icon__ECGRl"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22px"
                          height="22px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.88 6.225H16l-4.929 3.504-3.047 2.149-4.953 3.504L4.952 9.73 0 6.225h6.119L8 .572l1.881 5.653Zm1.596 4.812L8 11.9l4.929 3.527-1.453-4.392Z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </StyledItem>
                <StyledItemBottom>
                  <StyledInfo className="value_item">
                    <h6>
                      Recipient{" "}
                      <span className="captal">
                        {checkFinalizing.exchangenametofinal}
                      </span>{" "}
                      address:
                    </h6>
                    <h5>
                      <p>
                        {checkFinalizing.address}
                        <CopyToClipboard
                          text={checkFinalizing.address}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                  {checkFinalizing.memo ? (
                    <StyledInfo className="value_item">
                      <h6>EXTRA ID:</h6>
                      <h5>
                        <p>
                          {checkFinalizing.memo}
                          <CopyToClipboard
                            text={checkFinalizing.memo}
                            onCopy={() => ShowCopyToClipboardToast()}
                          >
                            <span>
                              <img src={Copy} alt="flashift" />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </h5>
                    </StyledInfo>
                  ) : null}
                  <StyledInfo className="value_item">
                    <h6>Hash:</h6>
                    <h5>
                      <p>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${explorerto}${checkFinalizing.hashto}`}
                        >
                          {checkFinalizing.hashto}
                        </a>
                        <CopyToClipboard
                          text={checkFinalizing.hashto}
                          onCopy={() => ShowCopyToClipboardToast()}
                        >
                          <span>
                            <img src={Copy} alt="flashift" />
                          </span>
                        </CopyToClipboard>
                      </p>
                    </h5>
                  </StyledInfo>
                </StyledItemBottom>
              </StyledRight>
            </StyledContent>
          ) : !checkFinalizing.statusmessage ? (
            <StyledLargLoading>
              <div class="loader">
                <span>L</span>
                <span>O</span>
                <span>A</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
              </div>
            </StyledLargLoading>
          ) : (
            <p className="error">{checkFinalizing.statusmessage}</p>
          )}
        </>
      ) : null}
    </div>
  );
};

const StyledHeader = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  div {
    span {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.color.ColorFive};
      margin: 0 3px;
      display: inline-block;
    }
    p {
      color: #f39c12;
      display: inline-block;
      text-align: center;
      position: relative;
      top: 21px;
      span {
        background: #f39c12;
        border: 1px solid ${(props) => props.theme.color.ColorEight};
      }
    }
  }
  button {
    img {
      &:last-child {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    > div {
      display: none;
    }
    button {
      position: absolute;
      right: 8px;
      top: 8px;
      img {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }
`;
const StyledContent = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  .finalleft_part,
  .finalright_part {
    width: 47.7%;
    margin: 0 10px;
  }

  .mobile_starBox {
    svg {
      position: relative;
      top: -4px;
      transition: all 100ms;
      transform: rotate(33deg);
      fill: #ffffff;
    }
    span {
      background: #f39c12;
      width: 25px;
      height: 25px;
      border-radius: 8px;
      display: inline-block;
      margin: 2px;
      transition: all 100ms;
    }
    .starBox {
      transform: rotate(180deg);
      width: 145px;
      display: flex;
      width: 145px;
      margin-top: 5px;
    }
    .starBox:hover span {
      background: #dcdce6;
    }
    .starBox span:hover {
      background: #f39c12;
    }
    .starBox span:hover ~ span {
      background: #f39c12;
    }
  }
  .mobile_starBox {
    display: none;
  }

  @media (max-width: 992px) {
    .finalleft_part,
    .finalright_part {
      width: 100%;
      margin: 0;
    }
    #finalright_part {
      margin-bottom: 100px;
    }
    .mobile_starBox {
      display: inline-block;
    }
  }
`;
const StyledRight = styled.div`
  width: 290px;
  margin: 20px;
  padding-top: 60px;
  position: relative;
  #pointone {
    &:hover {
      #popoverone {
        display: block;
      }
    }
  }
  #pointtwo {
    &:hover {
      #popovertwo {
        display: block;
      }
    }
  }
  #pointthree {
    &:hover {
      #popoverthree {
        display: block;
      }
    }
  }
  #pointfour {
    &:hover {
      #popoverfour {
        display: block;
      }
    }
  }
  img {
    width: 100%;
  }

  @media (max-width: 992px) {
    margin: 20px auto;
    padding-top: 0;
    .step_pic {
      display: none;
    }
  }
`;
const StyledTooltipPoint = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  &:nth-child(1) {
    right: 117px;
    top: 57px;
    > div {
      right: -36px;
      top: -74px;
    }
  }
  &:nth-child(2) {
    right: 5px;
    top: 166px;
    > div {
      right: 0;
      top: 65px;
    }
  }
  &:nth-child(3) {
    right: 115px;
    top: 278px;
    > div {
      right: -86px;
      bottom: -52px;
    }
  }
  &:nth-child(4) {
    right: 228px;
    top: 167px;
    > div {
      right: -169px;
      top: 60px;
    }
  }
`;
const StyledTooltipStep = styled.div`
  display: none;
  position: absolute;
  width: 260px;
  background: ${(props) => props.theme.color.ColorOne};
  border: 1px solid ${(props) => props.theme.color.ColorEight};
  border-radius: ${(props) => props.theme.radius.RadiusTwo};
  font-size: 0.8rem;
  padding: 7px;
  z-index: 1;
  p {
    &:first-child {
      span {
        color: #f39c12;
      }
    }
  }
`;
const StyledLeft = styled.div`
  width: calc(100% - 370px);
  margin: 20px;
  .error_message {
    color: ${(props) => props.theme.color.ColorTwelve};
    font-size: 1.3rem;
  }
  h2 {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    width: 550px;
    max-width: 100%;
    margin-bottom: 25px;

    p {
      font-size: 1rem;
      color: ${(props) => props.theme.color.ColorFive};
      margin-top: 5px;
      position: relative;
      span {
        color: #f39c12;
        font-size: 1.1rem;
        position: relative;
        top: 2px;
        margin-left: 2px;
        min-width: 60px;
        display: inline-block;
        text-align: center;
      }
      img {
        width: 20px;
        margin-left: 5px;
      }
      .overdue_icon {
        width: 30px;
        margin-left: 5px;
        position: relative;
        top: 12px;
      }
    }
  }
  > div {
    width: 550px;
    max-width: 100%;
  }
  @media (max-width: 992px) {
    width: 90%;
    margin: 0 auto;

    h2 {
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      display: inline-block;
      p {
        width: 100%;
        margin-top: 30px;
      }
    }
    .top_part {
      ul {
        display: unset;
      }
      li {
        width: 100%;
        text-align: left !important;
        margin: 3px auto;
        br {
          display: none;
        }
      }
    }
  }
`;
const StyledItemBottom = styled.div`
  border-radius: ${(props) => props.theme.radius.RadiusThree};
  background: ${(props) => props.theme.color.ColorTen};
  padding: 15px;
  margin-bottom: 20px;
  .networkfromfinal_item {
    border-radius: 5px;
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  .networktofinal_item {
    border-radius: 5px;
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  .button {
    background: #f39c12;
    color: #ffffff;
    border-radius: 8px;
    font-size: 1.1rem;
    padding: 3px 15px;
    margin: 12px 20px 0 0;
    position: relative;
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #ffffff;
    }
    &:hover {
      a {
        color: #f39c12 !important;
      }
    }
  }
  ul {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    li {
      .deactive {
        pointer-events: none;
        cursor: default;
      }
      text-align: center;
      .captal {
        color: #f39c12;
      }
      > span {
        margin: 5px auto;
        display: inline-block;
        &:first-child {
          color: ${(props) => props.theme.color.ColorFive};
        }
        &:last-child {
          color: #f39c12;
        }
      }
    }
  }
  @media (max-width: 992px) {
    width: 100% !important;
    background: none;
    margin: 0;
    padding: 0;
    .value_item {
      background: rgba(196, 201, 209, 0.32);
      border-radius: 8px;
      padding: 7px 10px;
      margin: 17px auto;
    }
  }
`;
const StyledInfo = styled.div`
margin-bottom:25px;
.required{
    font-size:1.3rem;
    font-weight:bold;
    margin-left:5px;
    filter: drop-shadow(2px 3px 1px #111a2b);
}
    &:last-child{
        margin-bottom:0;  
    }
    h6{
        color:${(props) => props.theme.color.ColorFive};
        margin-bottom:7px;
        span{
            font-weight:700;
            &:first-child{
                color:${(props) => props.theme.color.ColorTwo};  
            }
            &:last-child{
                color:#F39C12;
            }
        }
    }
    h5{
        color:#F39C12;
        a{
            color: #F39C12;
        }
        p{

            word-break: break-all;ّ
word-break: break-word; 
-webkit-hyphens: auto; 
-moz-hyphens: auto; 
hyphens: auto;
        }
        span {
            cursor:pointer;
            text-align: right;
            width:25px;
            margin-left:5px;
            img{
                width:12px;
            }
            small{
                color:#F39C12; 
                float: left;
    font-size: 0.8rem;
    margin-right: 2px;
    font-weight:normal !important;
            }
        }
    }
    @media(max-width:992px){
        h6{
            margin-bottom:0;
        }
    }
`;
const StyledQrcode = styled.div`
  margin-bottom: 30px;
  text-align: center;
  padding-top: 20px;
  img {
    margin: 0 0 0 10px;
    position: relative;
    top: 30px;
    width: auto;
  }
  h5 {
    display: inline-block;
  }
`;
const StyledItem = styled.div`
  background: url("./assets/images/success.svg") no-repeat center center;
  background-size: 130px;
  margin-top: 18px;
  h4 {
    color: #f39c12;
    font-weight: bold;
  }
  h1 {
    margin: 30px auto 10px auto;
    font-weight: bold;
  }
  P {
    opacity: 0.7;
    margin-bottom: 19px;
    font-size: 0.9rem;
  }
  svg {
    position: relative;
    transition: all 100ms;
    transform: rotate(33deg);
    fill: #ffffff;
  }
  span {
    background: #f39c12;
    width: 25px;
    height: 25px;
    border-radius: 8px;
    display: inline-block;
    margin: 2px;
    transition: all 100ms;
  }
  .starBox {
    transform: rotate(180deg);
    width: 145px;
    display: flex;
    width: 145px;
    margin-top: 5px;
  }
  .starBox:hover span {
    background: #dcdce6;
  }
  .starBox span:hover {
    background: #f39c12;
  }
  .starBox span:hover ~ span {
    background: #f39c12;
  }
  div {
    margin: 11px 0;
    svg {
      margin: 0 2px;
      transition: all 100ms;
      transform: rotate(33deg);
    }
  }
  @media (max-width: 992px) {
    display: none !important;
  }
`;
const StyledLoading = styled.div`
  text-align: center;
  display: inline-block;
  position: relative;
  top: 9px;
  img {
    animation: rotation 1s linear infinite;
    width: 30px;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

const StyledLargLoading = styled.div`
  text-align: center;
  margin-top: 50px;
  .loader {
    -webkit-perspective: 700px;
    perspective: 700px;
  }

  .loader > span {
    font-size: 40px;
    font-family: "franklin gothic medium", sans-serif;
    display: inline-block;
    animation: flip 2.6s infinite linear;
    transform-origin: 0 70%;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
  }

  @keyframes flip {
    35% {
      transform: rotateX(360deg);
    }
    100% {
      transform: rotatex(360deg);
    }
  }

  .loader > span:nth-child(even) {
    color: white;
  }

  .loader > span:nth-child(odd) {
    color: rgb(243, 156, 18);
  }

  .loader > span:nth-child(2) {
    animation-delay: 0.3s;
  }

  .loader > span:nth-child(3) {
    animation-delay: 0.6s;
  }

  .loader > span:nth-child(4) {
    animation-delay: 0.9s;
  }

  .loader > span:nth-child(5) {
    animation-delay: 1.2s;
  }

  .loader > span:nth-child(6) {
    animation-delay: 1.5s;
  }

  .loader > span:nth-child(7) {
    animation-delay: 1.8s;
  }
`;

const StyledButton = styled.div`
  width: 100% !important;
  margin-top: 15px;
  button {
    width: 100%;
    margin: 10px 10px 10px 0;
    padding: 10px 40px;
    font-size: 1.1rem;
    border-radius: 8px;
    border-radius: ${(props) => props.theme.radius.RadiusThree};
    color: ${(props) => props.theme.color.ColorTwo};
    background: #f39c12;
  }
  @media (max-width: 992px) {
    button {
      width: 100%;
      border-radius: 8px;
      margin: 0px auto;
      padding: 8px 40px;
    }
  }
`;

export default Finalizing;

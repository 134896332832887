import React from "react"
import styled from "styled-components";

const CardTitle = ({children}) => {
    return (
        <StyledWrapper className="rtl_title">
            {children}
        </StyledWrapper>
    )
}
const StyledWrapper = styled.h4`
background:${(props) => props.theme.color.ColorFour};
color:${(props) => props.theme.color.ColorTwo};
border-radius:${(props) => props.theme.radius.RadiusOne} ${(props) => props.theme.radius.RadiusOne} 0 0;
padding:8px 20px;
font-weight:700;
@media(max-width:768px){
    display:none;
}
`
export default CardTitle;
import React from "react"
import styled from "styled-components";
import { NavLink } from 'react-router-dom'
import TwitterLogo from "../../assets/images/footer/twitterlogo.svg"
import Linkone from "../../assets/images/footer/linkone.svg"
import Linktwo from "../../assets/images/footer/linktwo.svg"
import Linkthree from "../../assets/images/footer/linkthree.svg"


const Footer = () => {
    return (
        <StyledWrapper>
            <StyledItems>

                <StyledItem>
                    <StyledLink to="https://twitter.com/flashiftapp">
                        <img width={'23px'} src={TwitterLogo} alt="Twitter" />
                    </StyledLink>
                </StyledItem>

                <StyledItem>
                    <StyledLink to="https://medium.com/@flashift">
                        <img src={Linkone} alt="Medium" />
                    </StyledLink>
                </StyledItem>
                <StyledItem>
                    <StyledLink to="https://t.me/Flashift_support">
                        <img src={Linktwo} alt="Telegram" />
                    </StyledLink>
                </StyledItem>
                <StyledItem>
                    <StyledLink to="/">
                        <img src={Linkthree} alt="Email" />
                    </StyledLink>
                </StyledItem>

            </StyledItems>
            <p>
                © FLASHIFT. All Rights Reserved 2024. flashift.app
                <br />
                <StyledLink to="https://flashift.app/terms">
                    <span>
                        Terms of Use
                    </span>
                </StyledLink>
            </p>

        </StyledWrapper>

    )
}
const StyledWrapper = styled.footer`
padding: 10px 15px;
>p{
    color:${(props) => props.theme.color.ColorTwo};
    text-align: center;
font-size: 0.9rem;
span{
    color:${(props) => props.theme.color.ColorEight};
    display:inline-block;
    margin-top:3px;
}
}
`
const StyledItems = styled.ul`
display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:center;
align-items:center;
`
const StyledItem = styled.li`
margin:7px 30px;
`
const StyledLink = styled(NavLink)`
`

export default Footer;
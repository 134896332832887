import React from "react"
import styled from "styled-components";

const Card = ({children} ) => {
    return (
        <StyledWrapper>
            {children}
    </StyledWrapper>
    )
}
const StyledWrapper = styled.div`
background:${(props) => props.theme.color.ColorThree};
border-radius:${(props) => props.theme.radius.RadiusOne};
position:relative;
box-shadow:${(props) => props.theme.shadow.ShadowOne}; 
.comingsoon{
    position: absolute;
    z-index: 1;
    top:0;
    width: 100%;
    height:100%;
    text-align: center;
    opacity:0.7;
    left: 0;
    padding-top:20%;
img{
    width:270px;
    max-width:100%;
}
}
@media(max-width:768px){
background:transparent;
box-shadow:none;
}
`
export default Card;
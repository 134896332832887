import React  from "react"
import styled from "styled-components";

const ModalCoins= ({ children}) => {
  return (
    <StyledWrapper className="modal_item">
      <StyledInfo>
      {children}
      </StyledInfo>
    </StyledWrapper>
  )
}
const StyledWrapper = styled.div`
color:${(props) => props.theme.color.ColorTwo};
position: fixed;
width:100%;
height: 100%;
left: 0;
top: 0;
padding: 20px;
z-index: 1;
overflow:auto;
`
const StyledInfo = styled.div`
background:${(props) => props.theme.color.ColorSix};
border-radius:${(props) => props.theme.radius.RadiusOne};
position: absolute;
top:15vh;
left: calc(50vw - 215px);
width: 430px;
padding:20px 20px 0 20px;
height:72vh;
z-index:1;
@media(max-width:1300px){
left: 5%;
width: 90%;
}
@media(max-width:992px){
  left: 4%;
width: 92%;
  top:13%;
  padding:10px;
  }
  @media(max-width:768px){
    top:9%;
    }

`
export default ModalCoins;
import React, { useContext } from "react";
import styled from "styled-components";
import TransactionContext from "../../../../../Contexts/Transaction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Close from "../../../../../assets/images/modal/close.svg";
import MobileClose from "../../../../../assets/images/modal/mobileclose.svg";
import MobileBack from "../../../../../assets/images/modal/mobileback.svg";
import StepTwo from "../../../../../assets/images/modal/step2.svg";
import Loading from "../../../../../assets/images/loadingwhite.svg";

const Confirm = () => {
  const checkConfirm = useContext(TransactionContext);
  return (
    <>
      <ToastContainer />
      <StyledHeader>
        <button onClick={checkConfirm.HandleClose}>
          <img src={Close} alt="flashift" />
          <img src={MobileClose} alt="flashift" />
        </button>
        <div>
          <span></span>
          <span></span>
          <p>
            <span></span>
            <br />3
          </p>
          <span></span>
        </div>
      </StyledHeader>
      <StyledContent>
        <StyledLeft>
          <h2>Confirm the transaction</h2>
          <StyledItem>
            <StyledInfo className="info_items">
              <div>
                <h6>You Send :</h6>
                <h5>
                  {checkConfirm.cefivaluefrom}
                  <span className="captal">
                    {checkConfirm.exchangenamefrom}
                  </span>
                  <span
                    className={
                      checkConfirm.networkfrom ? "networkfrom_item" : null
                    }
                  >
                    {checkConfirm.networkfrom}
                  </span>
                </h5>
              </div>
              <div>
                <h6>You Get :</h6>
                <h5>
                  {checkConfirm.cefivalueto}
                  <span className="captal">{checkConfirm.exchangenameto}</span>
                  <span
                    className={checkConfirm.networkto ? "networkto_item" : null}
                  >
                    {checkConfirm.networkto}
                  </span>
                </h5>
              </div>
            </StyledInfo>
            <StyledInfo className="info_items">
              <div>
                <h6>Exchange Partner :</h6>
                <h5>{checkConfirm.service}</h5>
              </div>
              <div>
                <h6>Exchange Type :</h6>
                <h5>
                  {checkConfirm.exchangetype.charAt(0).toUpperCase() +
                    checkConfirm.exchangetype.slice(1)}
                </h5>
              </div>
            </StyledInfo>
          </StyledItem>
          <StyledItemBottom>
            <StyledInfo className="address_value">
              <h6>
                Destination {checkConfirm.exchangenameto.toUpperCase()} address:
              </h6>
              <h5>
                <textarea value={checkConfirm.address} readOnly />
              </h5>
            </StyledInfo>
          </StyledItemBottom>
          <StyledItemBottom className={checkConfirm.memo ? "" : "activeMemo"}>
            {checkConfirm.memo ? (
              <StyledInfo className="address_value">
                <h6>Extra ID:</h6>
                <h5>
                  <textarea value={checkConfirm.memo} readOnly />
                </h5>
              </StyledInfo>
            ) : null}
          </StyledItemBottom>
          <StyledButton>
            <button onClick={checkConfirm.HandleModalone}>
              Back
              <img src={MobileBack} alt="flashift" />
            </button>
            <div>
              <button
                onClick={checkConfirm.HandleModalthree}
                className={checkConfirm.waiting ? "disabled" : null}
              >
                {checkConfirm.waiting ? (
                  <StyledLoading>
                    waiting
                    <img src={Loading} alt="flashift" />
                  </StyledLoading>
                ) : (
                  "Next"
                )}
              </button>
            </div>
          </StyledButton>
          <StyledTerm>
            By confirming this transaction, you agree to our{" "}
            <a href="https://flashift.app/terms">terms of use </a>
          </StyledTerm>
        </StyledLeft>
        <StyledRight>
          <StyledTooltipPoint id="pointone">
            <StyledTooltipStep id="popoverone">
              <div>
                <p>
                  <span>Step 1 </span>Select rate
                </p>
                <p>Select the exchange service which meets your requirements</p>
              </div>
            </StyledTooltipStep>
          </StyledTooltipPoint>
          <StyledTooltipPoint id="pointtwo">
            <StyledTooltipStep id="popovertwo">
              <div>
                <p>
                  <span> Step 2</span> Enter address
                </p>
                <p>Enter your destination address and MEMO</p>
              </div>
            </StyledTooltipStep>
          </StyledTooltipPoint>

          <StyledTooltipPoint id="pointthree">
            <StyledTooltipStep id="popoverthree">
              <div>
                <p>
                  <span> Step 3</span> Conform
                </p>
                <p>Reveiw everything before conforming</p>
              </div>
            </StyledTooltipStep>
          </StyledTooltipPoint>
          <StyledTooltipPoint id="pointfour">
            <StyledTooltipStep id="popoverfour">
              <div>
                <p>
                  <span>Step 4</span> Receive
                </p>
                <p>You will get your coins in a few minutes</p>
              </div>
            </StyledTooltipStep>
          </StyledTooltipPoint>
          <img src={StepTwo} alt="flashift" />
        </StyledRight>
      </StyledContent>
    </>
  );
};

const StyledHeader = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  div {
    span {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.color.ColorFive};
      margin: 0 3px;
      display: inline-block;
    }
    p {
      color: #f39c12;
      display: inline-block;
      text-align: center;
      position: relative;
      top: 21px;
      span {
        background: #f39c12;
        border: 1px solid ${(props) => props.theme.color.ColorEight};
      }
    }
  }
  button {
    img {
      &:last-child {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    > div {
      display: none;
    }
    button {
      position: absolute;
      right: 8px;
      top: 8px;
      img {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }
`;
const StyledContent = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  .activeMemo {
    padding: 0;
  }
`;
const StyledRight = styled.div`
  width: 290px;
  margin: 20px;
  padding-top: 60px;
  position: relative;
  #pointone {
    &:hover {
      #popoverone {
        display: block;
      }
    }
  }
  #pointtwo {
    &:hover {
      #popovertwo {
        display: block;
      }
    }
  }
  #pointthree {
    &:hover {
      #popoverthree {
        display: block;
      }
    }
  }
  #pointfour {
    &:hover {
      #popoverfour {
        display: block;
      }
    }
  }
  img {
    width: 100%;
  }
  @media (max-width: 992px) {
    img {
      display: none;
    }
  }
`;
const StyledTooltipPoint = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  &:nth-child(1) {
    right: 117px;
    top: 57px;
    > div {
      right: -36px;
      top: -74px;
    }
  }
  &:nth-child(2) {
    right: 5px;
    top: 166px;
    > div {
      right: 0;
      top: 65px;
    }
  }
  &:nth-child(3) {
    right: 115px;
    top: 278px;
    > div {
      right: -86px;
      bottom: -68px;
    }
  }
  &:nth-child(4) {
    right: 228px;
    top: 167px;
    > div {
      right: -169px;
      top: 59px;
    }
  }
`;
const StyledTooltipStep = styled.div`
  display: none;
  position: absolute;
  width: 260px;
  background: ${(props) => props.theme.color.ColorOne};
  border: 1px solid ${(props) => props.theme.color.ColorEight};
  border-radius: ${(props) => props.theme.radius.RadiusTwo};
  font-size: 0.8rem;
  padding: 7px;
  z-index: 1;
  p {
    &:first-child {
      span {
        color: ${(props) => props.theme.color.ColorEight};
      }
    }
  }
`;
const StyledLeft = styled.div`
  width: calc(100% - 370px);
  margin: 20px;
  h2 {
    margin-bottom: 25px;
  }
  > div {
    width: 550px;
    max-width: 100%;
  }
  @media (max-width: 992px) {
    width: 90%;
    margin: 0 auto;
    > div {
      width: 100%;
    }
    h2 {
      margin-bottom: 20px;
      text-align: center;
    }
  }
`;
const StyledItem = styled.div`
padding:0 15px;
@media(max-width:992px){
    padding:0;
.info_items{
    width: 100%;
    text-align: left;
    margin: 3px auto;
    >div{
        width:100%;
        margin: 3px auto;
        display:flex;
display:-ms-flexbox;
flex-wrap:wrap;
-ms-flex-wrap:wrap;
justify-content:left;
h6,h5{
    width:unset;
}
    }
}
`;
const StyledItemBottom = styled.div`
  border-radius: ${(props) => props.theme.radius.RadiusTwo};
  background: ${(props) => props.theme.color.ColorTen};
  padding: 0;
  margin-bottom: 12px;
  > div {
    margin-bottom: 10px;
  }
  .address_value {
    background: rgba(196, 201, 209, 0.32);
    border-radius: 8px;
    padding: 7px 15px;
    margin: 17px auto;
  }
  @media (max-width: 992px) {
    background: none;
  }
`;
const StyledInfo = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
  .networkfrom_item {
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  .networkto_item {
    border-radius: ${(props) => props.theme.radius.RadiusTwo};
    height: 20px;
    min-width: 40px;
    text-align: center;
    padding: 1px 5px;
    border: 1px solid #f39c12;
    color: #f39c12;
    font-size: 0.8rem;
    margin-left: 5px;
  }
  h6 {
    color: ${(props) => props.theme.color.ColorFive};
    margin-bottom: 7px;
    width: 100%;
  }
  textarea {
    color: rgb(243, 156, 18);
  }
  h5 {
    width: 100%;
    margin-left: 3px;
    span {
      color: #f39c12;
      margin-left: 5px;
    }
    p {
      color: #f39c12;
      margin-left: 5px;
      overflow-x: auto;
    }
  }
  @media (max-width: 992px) {
    h6 {
      margin-bottom: 0;
    }
  }
`;
const StyledButton = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: left;
  width: 500px;
  max-width: 100%;
  margin-top: 15px;
  .disabled {
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
  }
  > button {
    img {
      display: none;
    }
  }
  button {
    width: 122px;
    margin: 10px;
    padding: 10px;
    font-size: 1.1rem;
    border-radius: ${(props) => props.theme.radius.RadiusThree};
    position: relative;
    min-height: 47px;
    &:first-child {
      background: ${(props) => props.theme.color.ColorFive};
      color: ${(props) => props.theme.color.ColorOne};
    }
    &:last-child {
      background: #f39c12;
      color: ${(props) => props.theme.color.ColorTwo};
    }
  }
  @media (max-width: 992px) {
    width: 100%;
    > button {
      img {
        display: block;
      }

      &:first-child {
        background: unset;
        color: unset;
        position: absolute;
        top: 10px;
        left: 7px;
        margin: 0;
        padding: 0;
        font-weight: normal;
        background: none;
        font-size: 0;
      }
    }
    > div {
      width: 100%;
      button {
        width: 100%;
        border-radius: 8px;
        margin: 10px auto;
        padding: 8px 40px;
        min-height: 35px;
      }
    }
  }
`;
const StyledTerm = styled.div`
  margin-top: 10px;
  color: ${(props) => props.theme.color.ColorTwo};
  font-size: 0.9rem;
  padding: 0 10px;
  a {
    color: #f39c12;
  }
`;

const StyledLoading = styled.div`
  background: #f39c12;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 41.6px;
  right: 0;
  top: 0;
  padding: 8px 15px;
  img {
    animation: rotation 1s linear infinite;
    width: 20px;
    position: relative;
    top: 6px;
    margin-left: 5px;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
export default Confirm;

import React, { useContext } from "react";
import styled from "styled-components";
import TransactionContext from "../../Contexts/Transaction";
import TopBar from "../../Components/TopBar";
//import SideBar from "../../Components/SideBar"

const Page = ({ children }) => {
  const checkpage = useContext(TransactionContext);
  return (
    <StyledWrapper onClick={checkpage.HandleMainClose}>
      <TopBar />
      <div className="background_container">
        <StyledInfo>
          {/*<SideBar/>*/}
          <StyledContent className="child_wrapper">{children}</StyledContent>
        </StyledInfo>
      </div>
    </StyledWrapper>
  );
};
const StyledWrapper = styled.div`
  background: ${(props) => props.theme.color.ColorOne};
  height: 100vh;
`;
const StyledInfo = styled.main`
  padding: 0px 70px 10px;
  @media (max-width: 768px) {
    height: calc(100vh - 45px);
    padding: 0;
  }
`;
const StyledContent = styled.section`
  margin-top: 5vh;
  height: 100%;
  scroll-behavior: smooth;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #b4c9f2 #c2c2c2;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #c2c2c2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b4c9f2;
    border-radius: 8px;
    border: 1px solid #ffffff;
  }
  @media (max-width: 768px) {
    margin-top: 0vh;
    width: 100%;
    padding: 0;
  }
`;

export default Page;
